<template>
  <div class="textarea-wrapper">
    <span class="textarea-counter typography-menu _regular _small" data-textarea-counter>
      {{ count }} / {{ maxlength }}
    </span>
    <textarea
      :name="textareaName"
      :class="['textarea', className]"
      :type="type"
      :placeholder="placeholder"
      v-bind="$attrs"
      :value="value"
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    textareaName: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    maxlength: {
      type: Number,
      required: false,
      default: 1200,
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    handleInput(event) {
      if (event.target.value.length >= this.maxlength) {
        event.target.value = event.target.value.substring(0, this.maxlength);
      }
      this.count = event.target.value.length;
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style lang="scss">
@import "./textarea.scss";
</style>
