import mockedParameters from "@/data/mocked/parameters";
import promocodeCount from "@/data/mocked/promocode-count";
import promocodePercent from "@/data/mocked/promocode-percent";
import { MODAL_MODES } from "@/js/constants";
import { OrderAPI } from "@/api/order";

const initialState = {
  platform: "",
  activity: "",
  params: {
    platforms: [],
    default_activities: {
      title: "Выберите активность",
      value: "",
      isActive: true,
      isDisabled: true,
    },
    activities: [],
    default_quality_activities: null,
    quality_activities: [],
    qualityActivityDescription: "",
    countries: [],
    genders: [],
    isActivitiesAvailable: false,
    isParamsAvailable: false,
  },
  quality_activity: "",
  count: "",
  linkToRecource: "",
  email: "",
  message: "",
  country: "",
  gender: "",
  order_id: "",
  promocode: null,
  costPerThousand: 0,
  parameterPriceDiff: 0,
  countryIndex: 1,
  genderIndex: 1,
  paymentSystems: [],
  lastUnpaidOrder: null,
  isValidLink: false,
  isValidEmail: false,
  isOrderSubmit: false,
  isNewUser: false,
};

const orderState = initialState;

const actions = {
  initOrderParams({ dispatch }) {
    dispatch("initPlatforms");
    dispatch("initActivities", 1);
    dispatch("initParams", 1);
  },
  initPlatforms({ commit }) {
    OrderAPI.getPlatforms()
      .then((res) => {
        const data = res.data.data;
        return data.map((platform) => ({
          ...platform,
          title: platform.name,
          isActive: false,
          domain_link: platform.domain_link,
        }));
      })
      .then((data) => {
        commit("setPlatforms", data);
      });
  },
  initActivities({ state, commit }, id) {
    state.activity = "";
    state.params.default_activities.isActive = true;
    state.params.default_activities.hasComment = false;
    OrderAPI.getActivities(id)
      .then((res) => {
        const data = res.data;
        return data.map((activity) => ({
          ...activity,
          isActive: false,
        }));
      })
      .then((data) => {
        commit("setActivities", data);
        commit("setActivitiesAvailable", true);
      });
  },
  initParams({ commit, getters }, id) {
    const categoryId = getters.getPlatform === "" ? 1 : getters.getPlatform;
    ( OrderAPI.getParams(categoryId, id))
        .then(res => res.data)
        .then(services => {
          let defaultQualityActivities = {
            ...mockedParameters.default_quality_activities,
            isActive: true,
            isDisabled: false,
          }
          if (services.length > 0) {
            services[0].isActive = true
            defaultQualityActivities = {
              ...services[0],
              isDisabled: false,
            }
          }

           commit("setDefaultQualityActivities", defaultQualityActivities);
           commit("setCount", services[0]?.min_amount || 0);
           commit("setQualityActivities", services);
           commit("setParamsAvailable", true);
           commit("setCostPerThousand", services[0]?.price || 0);
        })
  },
  setPlatform({ commit, dispatch }, id) {
    dispatch("initActivities", id);
    commit("setActivitiesAvailable", false);
    commit("setPlatform", id);
  },
  setActivity({ commit, dispatch }, id) {
    dispatch("initParams", id);
    commit("setParamsAvailable", false);
    commit("setActivity", id);
  },
  setPromocode({ commit, rootState }, {code, email}) {
    // TODO: remove late
    OrderAPI.validatePromocode(code, email)
      .then(res => res.data)
      .then(res => {
        let promocodeData = null;
        if (res.count) {
          promocodeData = res;
        }

        if (!promocodeData) {
          rootState.modal.mode = MODAL_MODES.promocodeReject;
        } else {
          rootState.modal.mode = MODAL_MODES.promocodeAccept;
          commit("setPromocode", promocodeData);
        }
      })
        .catch(res => {
          rootState.modal.mode = MODAL_MODES.promocodeReject
          commit("setPromocode", res.response.data);

        });
  },
  sendOrder({ state }) {
    const postData = {
      category: state.platform,
      service: state.quality_activity,
      activity: state.activity,
      link: state.linkToRecource,
      quantity: state.count,
      email: state.email,
      promocode_code: state.promocode?.code,
    };

    if (state.country !== "all" && state.country) {
      postData.country = state.country;
      if (!state.gender) {
        state.gender = "any";
      }
      postData.gender = state.gender;
    }

    return OrderAPI.postOrder(postData);
  },

  sendOrderUnauthenticated({ state }) {
    const postData = {
      category: state.platform,
      service: state.quality_activity,
      activity: state.activity,
      link: state.linkToRecource,
      quantity: state.count,
      email: state.email,
      promocode_code: state.promocode?.code,
    };

    if (state.country !== "all" && state.country) {
      postData.country = state.country;
      if (!state.gender) {
        state.gender = "any";
      }
      postData.gender = state.gender;
    }

    return OrderAPI.postOrderUnauthenticated(postData);
  },
  getPaymentSystems({ commit }) {
    OrderAPI.getPaymentSystems().then((res) => {
      commit(
        "setPaymentSystems",
        res.data.data.map((item, index) => ({
          ...item,
          id: index,
        }))
      );
    });
  },
};

const mutations = {
  setPlatform(state, data) {
    state.platform = data;
  },
  setLastUnpaidOrder(state, data) {
    state.lastUnpaidOrder = data;
  },
  setActivity(state, data) {
    state.activity = data;
  },
  setActivities(state, data) {
    state.params.activities = data;
  },
  setDefaultActivities(state, data) {
    state.params.default_activities = data;
  },
  setPlatforms(state, data) {
    state.params.platforms = data;
  },
  setDefaultQualityActivities(state, data) {
    state.params.default_quality_activities = data;
  },
  setQualityActivities(state, data) {
    const newData = JSON.parse(JSON.stringify(data))

    const formatedServices = newData.map((service, key) => {

      return {
        id: service.id,
        title: service.title,
        price: service.price,
        isActive: key === 0,
        isDisabled: false,
        minAmount: service.min_amount,
        maxAmount: service.max_amount,
        description: service.description,
        parameters: JSON.parse(JSON.stringify(service.parameters)).map((parameter, keyParameter) => ({
          ...parameter,
          title: parameter.title,
          id: parameter.id,
          value: String(parameter.id),
          isActive: keyParameter === 0,
          genders: JSON.parse(JSON.stringify(parameter?.genders) || []).map((gender, keyGender) => ({
            ...gender,
            isActive: keyGender === 0,
            id: gender.value
          }))
        })),
      };
    });

    state.gender = null
    if (Object.keys(formatedServices).length > 0) {
      const defaultService = [...formatedServices].shift();

      state.quality_activity = defaultService.id;
      state.params.default_quality_activities = defaultService;


      if (defaultService?.parameters.length > 0) {
        state.params.countries = [...defaultService?.parameters];
        const firstParams = [...defaultService?.parameters].shift() || []
        state.country = firstParams.value;
        if (firstParams.genders.length > 0) {
          state.params.genders = firstParams.genders
          const firstGender = [...firstParams.genders].shift()
          this.gender = firstGender?.value
          state.gender = firstGender?.value
          state.parameterPriceDiff = parseFloat(firstGender.price_diff) || 0
        }
      }

      state.params.qualityActivityDescription = defaultService.description;
    } else {
      state.params.qualityActivityDescription = "";
    }
    state.params.quality_activities = formatedServices;
  },
  setGenders(state, data) {
    state.params.genders = data;
  },
  resetState(state) {
    state.promocode = {}
  },
  setQualityActivityDescription(state, data) {
    state.params.qualityActivityDescription = data;
  },
  setCountries(state, data) {
    state.params.countries = data;
  },
  setCostPerThousand(state, data) {
    state.costPerThousand = data;
  },
  setParameterPriceDiff(state, data) {
    state.parameterPriceDiff = data;
  },
  setGender(state, data) {
    state.gender = data;
  },
  setCountry(state, data) {
    state.country = data;
  },
  setQualityActivity(state, data) {
    state.quality_activity = data;
    if (!data) {
      state.params.countries = [];
      return;
    }
    state.params.countries = state.params.quality_activities.find(
      ({ id }) => id === data
    )?.parameters;
  },
  setCount(state, count) {
    state.count = count;
  },
  setOrderId(state, order_id) {
    state.order_id = order_id;
  },
  setLink(state, link) {
    state.linkToRecource = link;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setPromocode(state, data) {
    state.promocode = data;
  },
  setActivitiesAvailable(state, data) {
    state.params.isActivitiesAvailable = data;
  },
  setParamsAvailable(state, data) {
    state.params.isParamsAvailable = data;
  },
  setCountryIndex(state, data) {
    state.countryIndex = data;
  },
  setGenderIndex(state, data) {
    state.genderIndex = data;
  },
  setPaymentSystems(state, data) {
    state.paymentSystems = data;
  },
  setOrderSubmit(state, data) {
    state.isOrderSubmit = data;
  },
  setValidLink(state, data) {
    state.isValidLink = data;
  },
  setValidEmail(state, data) {
    state.isValidEmail = data;
  },
  setNewUser(state, data) {
    state.isNewUser = data;
  }
};

const getters = {
  getPlatform(state) {
    return state.platform;
  },
  getLastUnpaidOrder(state) {
    return state.lastUnpaidOrder;
  },
  getQualityActivity(state) {
    return state.quality_activity;
  },
  getQualityActivityDescription(state) {
    return state.params.qualityActivityDescription;
  },
  getActivity(state) {
    return state.activity;
  },
  getPlatforms(state) {
    return state.params.platforms;
  },
  getActivities(state) {
    return state.params.activities;
  },
  getDefaultActivities(state) {
    return state.params.default_activities;
  },
  getSelectedActivity(state) {
    if (state.params.default_activities.isActive) {
      return state.params.quality_activities;
    }

    return state.params.activities.find((el) => {
      return el.isActive === true;
    });
  },
  getQualityActivities(state) {
    return state.params.quality_activities;
  },
   getSelectedQualityActivity(state) {

    if (
      state.params.quality_activities?.isActive ||
      state.params.quality_activities?.id === state.activity
    ) {
      return state.params.quality_activities;
    }
    const quality_activities = JSON.parse(JSON.stringify(state.params.quality_activities));
    let res = null;
    quality_activities.forEach(item => {
      item.parameters = JSON.parse(JSON.stringify(item.parameters))
      if (item?.isActive === true || item.id === state.activity) {
        res = {...item}
      }
    })

    return res
  },
  // eslint-disable-next-line sonarjs/no-identical-functions
  getSelectedQualityActivityId(state, id) {

    if (
        state.params.quality_activities?.isActive ||
        state.params.quality_activities?.id === state.activity
    ) {
      return state.params.quality_activities;
    }
    const quality_activities = JSON.parse(JSON.stringify(state.params.quality_activities));
    let res = null;
    // eslint-disable-next-line sonarjs/no-identical-functions
    quality_activities.forEach(item => {
      item.parameters = JSON.parse(JSON.stringify(item.parameters))
      if (item?.isActive === true || item.id === state.activity) {
        res = {...item}
      }
    })

    return res
  },
  getDefaultQualityActivities(state) {
    return state.params.default_quality_activities;
  },
  getGenders(state) {
    return state.params.genders;
  },
  getGender(state) {
    return state.gender;
  },
  getCountries(state) {
    return state.params.countries ?? [];
  },
  getCount(state) {
    return state.count;
  },
  getLink(state) {
    return state.linkToRecource;
  },
  getEmail(state) {
    return state.email;
  },
  getTotalPrice(state) {
    const finalCount = state.count;
    let finalCostPerThousand = parseInt(state.costPerThousand, 10) + state.parameterPriceDiff;
    let finalPrice = 0.0;

    if (state.promocode && state.promocode.type === "percent") {
      finalCostPerThousand *= 1 - state.promocode.count / 100;
    }

    finalPrice = (finalCostPerThousand / 1000) * finalCount;

    if (state.promocode && state.promocode.type === "count") {
      finalPrice -= parseFloat(state.promocode.count);
    }

    if (finalCount <= 0 || finalCostPerThousand <= 0 || finalPrice <= 0) {
      return 0;
    }

    return finalPrice;
  },
  getCostPerThousand(state) {
    return state.costPerThousand;
  },
  getMessage(state) {
    return state.message;
  },
  isPromoActivated(state) {
    return Boolean(state.promocode?.code);
  },
  getPromocodeText(state) {
    return state.promocode?.message;
  },
  getPromocodeCode(state) {
    return state.promocode?.code;
  },
  isActivitiesAvailable(state) {
    return state.params.isActivitiesAvailable && state.platform;
  },
  isParamsAvailable(state) {
    return state.params.isParamsAvailable && state.activity;
  },
  getPaymentSystems(state) {
    return state.paymentSystems;
  },
  getOrderSubmit(state) {
    return state.isOrderSubmit;
  },
  getValidLink(state) {
    return state.isValidLink;
  },
  getValidEmail(state) {
    return state.isValidEmail;
  },
  getOrderId(state) {
    return state.order_id;
  },
  isNewUser(state) {
    return state.isNewUser;
  }
};

const store = {
  namespaced: true,
  state: orderState,
  actions,
  mutations,
  getters,
};

export default store;
