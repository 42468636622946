import { DefaultAPIInstance } from "@/api";

export const StaticPagesAPI = {
  getPages() {
    const url = "/static-pages";
    return DefaultAPIInstance.get(url);
  },
  getPageBy(slug) {
    const url = `/static-pages/${slug}`;
    return DefaultAPIInstance.get(url);
  },
};
