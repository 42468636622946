import { OrderAPI } from "@/api/order";

const historyState = {
  history: null,
  hasMoreItems: true,
};

const actions = {
  loadHistory({ commit, state }) {
    OrderAPI.getOrderHistory().then((res) => {
      state.hasMoreItems = false;
      commit("addHistory", res.data.data);
    });
  },
};

const mutations = {
  addHistory(state, data) {
    if (!state.history) {
      state.history = data;
    } else {
      state.history = state.history.concat(data);
    }
  },
};

const getters = {
  getHistory(state) {
    return state.history;
  },
  hasMoreItems(state) {
    return state.hasMoreItems;
  },
};

const store = {
  namespaced: true,
  state: historyState,
  actions,
  mutations,
  getters,
};

export default store;
