<template>
  <div class="order-parameters" data-order-parameters>
    <ul class="order-parameters__list">
      <li class="order-parameters__item order-parameters__item_medium">
        <div class="order-parameters__item-heading typography-menu _regular _middle"
             @mouseover="setShowQuestion(true)"
             @mouseleave="setShowQuestion(false)"
             @click="setShowQuestion(!showQuestion)"
        >
          <div v-show="showQuestion" class="question__hover">
            <div class="question__hover_content" v-html="this.formatText()" />
          </div>
          <span class="order-parameters__item-title">Качество активностей:</span>
          <Icon class="order-parameters__item-icon" name="question" />
        </div>
        <Select
            :items="getQualityActivities"
            :defaultValue="getQualityActivities.length > 0 ? null : defaultValueActivities "
            selectName="quality-activities"
            class="select_small _small"
            @handleSelect="handleSelectActivities"
        />
      </li>
      <li class="order-parameters__item order-parameters__item_medium">
        <div class="order-parameters__item-heading typography-menu _regular _middle">
          <span class="order-parameters__item-title">Количество:</span>
        </div>
        <InputNumber
            v-model="count"
            inputName="count"
            class="typography-menu _medium _big"
            type="number"
            placeholder="50"
        />
        <div v-if="getDefaultQualityActivities.minAmount && getDefaultQualityActivities.maxAmount" class="order-summary__submit-text typography-menu _middle">
          Лимиты: от {{ getDefaultQualityActivities?.minAmount }} до {{ getDefaultQualityActivities?.maxAmount }}
        </div>
      </li>
      <li class="order-parameters__item order-parameters__item">
        <div class="order-parameters__item-heading typography-menu _regular _middle">
          <span class="order-parameters__item-title"> Ссылка на материал: </span>
        </div>
        <Input v-model="link" @keyup.native="validLink" inputName="link" class="typography-menu _medium _big" placeholder="Вставьте вашу ссылку" required />

        <button
            v-show="isValidLink.error"
            type="button"
            class="input-button"
        >
          <Icon :class="['input-button__icon', { _disabled: !isParamsAvailable }]" name="input-fail"/>
        </button>

        <button
            v-show="isValidLink.pending"
            type="button"
            class="input-button"
        >
          <Icon class="input-button__icon" name="input-preloader"/>
        </button>
      </li>
      <li class="order-parameters__item order-parameters__item">
        <div class="order-parameters__item-heading typography-menu _regular _middle">
          <span class="order-parameters__item-title"> Ваш E-mail </span>
          <div v-if="!getUserEmail">
            &nbsp;или
            <a href="#" class="order-parameters__item-link" @click.prevent="handleShowModalEntry"
            >Выполните вход</a
            >
          </div>
        </div>
        <InputEmail v-model="email" @keyup.native="validEmail" inputName="email" class="typography-menu _medium _big" required />

        <button
            v-if="!isValidEmail"
            type="button"
            class="input-button"
        >
          <Icon :class="['input-button__icon', { _disabled: !isParamsAvailable }]" name="input-fail"/>
        </button>
      </li>
      <li v-if="getActivities.find(item => item.id === getActivity)?.has_comment" class="order-parameters__item">
        <div class="order-parameters__item-heading typography-menu _regular _middle">
          <span class="order-parameters__item-title"> Ваш комментарий (ссылка) </span>
        </div>
        <Textarea
            v-model="message"
            textareaName="text"
            className="typography-menu _regular _small"
            placeholder="Пример текста"
            :maxlength="1200"
        />
      </li>
    </ul>
    <div class="order-parameters-extra" v-if="getCountries.length > 0">
      <h3 class="order-parameters-extra__title typography-heading _medium">
        Дополнительные параметры
      </h3>
      <ul class="order-parameters__list order-parameters__list_extra">
        <li class="order-parameters__item order-parameters__item_small">
          <div class="order-parameters__item-heading">
            <span class="order-parameters__item-title typography-menu _regular _middle">
              Выберите страну
            </span>
          </div>

          <Select
              :items="getCountries"
              :defaultValue="getCountries.length > 0 ? null : defaultValueCountries"
              selectName="country"
              class="select_small _small"
              @handleSelect="handleSelectCountries"
          />
        </li>
        <li class="order-parameters__item order-parameters__item_small">
          <div class="order-parameters__item-heading">
            <span class="order-parameters__item-title typography-menu _regular _middle">
              Выберите пол
            </span>
          </div>
          <Select
              :items="getGenders"
              :defaultValue="getGenders.length > 0 ? null : defaultValueGenders"
              selectName="genger"
              class="select_small _small"
              @handleSelect="handleSelectGenders"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import Select from "@/components/select/Select";
import Input from "@/components/input/Input";
import InputNumber from "@/components/input-number/InputNumber";
import InputEmail from "@/components/input-email/InputEmail";
import Textarea from "@/components/textarea/Textarea";
import { MODAL_MODES } from "@/js/constants";
import { OrderAPI } from "@/api/order";

export default {
  name: "OrderParameters",
  components: {
    Icon,
    Select,
    Input,
    InputNumber,
    InputEmail,
    Textarea,
  },

  data() {
    return {
      defaultValueActivities: {
        title: "Выберите из списка",
        value: "alive",
        isActive: true,
        isDisabled: false,
      },
      defaultValueCountries: {
        title: "Любая",
        value: "all",
        isActive: true,
        isDisabled: false,
      },
      defaultValueGenders: {
        title: "Любой",
        value: "all",
        isActive: true,
        isDisabled: false,
      },
      showQuestion: false,
      isValidLink: {
        error: true,
        pending: false,
        success: false
      },
      isValidEmail: false,
      // domain_link: 'https://',
      gTimer: 0,
    };
  },
  computed: {
    orderSubmit: {
      set(value) {
        this.setOrderSubmit(value);
      },
      get() {
        return this.getOrderSubmit;
      }
    },
    count: {
      set(value) {
        this.setCount(value);
      },
      get() {
        return this.getCount;
      },
    },
    countries: {
      set(value) {
        this.setCountries(value);
      },
      get() {
        return this.setCountries;
      },
    },
    link: {
      set(value) {
        this.setLink(value);
      },
      get() {
        return this.getLink;
      },
    },
    email: {
      set(value) {
        this.setEmail(value);
      },
      get() {
        return this.getEmail;
      },
    },
    message: {
      set(value) {
        this.setMessage(value);
      },
      get() {
        return this.getMessage;
      },
    },

    getUserEmail() {
      return this.getUser?.email || "";
    },

    ...mapGetters("order", [
      "getCountries",
      "getGenders",
      "getGender",
      "getQualityActivities",
      "getDefaultQualityActivities",
      "getActivity",
      "getActivities",
      "getPlatform",
      "getPlatforms",
      "getCount",
      "getLink",
      "getEmail",
      "getMessage",
      "getCostPerThousand",
      "getQualityActivityDescription",
      "getQualityActivity",
      "getOrderSubmit",
      "getValidEmail",
      "getValidLink",
      "getSelectedQualityActivity",
      "getQualityActivities",
      "isParamsAvailable",
    ]),
    ...mapGetters("user", ["getUser", "isUserAuthed", "getBalance"]),
  },
  watch: {
    getUserEmail(value) {
      this.email = value;

      if (value) {
        this.validEmail()
      }
    },
  },
  updated() {
    // this.domain_link = this.getPlatforms?.find(item => item.id === this.getPlatform)?.domain_link
    // const domain_link = this.domain_link
    // if (domain_link) {
    //   if (this.link.indexOf(domain_link) >= 0) {
    //     this.link = domain_link + this.link.replace(domain_link, '')
    //   } else {
    //     this.link = domain_link
    //   }
    // }

    this.orderSubmit = this.isValidLink.success && this.isValidEmail;
  },
  mounted() {
    this.setEmail(this.getUserEmail);
    this.validEmail()
    // this.link = 'https://';
    this.setQualityActivity(this.defaultValueActivities.value);
    if (this.getCountries.length === 0) {
      this.setCountry(this.defaultValueCountries.value);
    }

    if (this.getGenders.length === 0) {
      this.setGender(this.defaultValueGenders.value);
    }

  },
  methods: {
    setIsValidLink(match) {
      this.isValidLink.error = false;
      this.isValidLink.success = false;
      this.isValidLink.pending = false;
      this.isValidLink[match] = true;

    },
    validLink(link) {
      this.setIsValidLink('pending')
      if (this.gTimer) {
        clearTimeout(this.gTimer);
      }
      const value = link.target.value;
      const domain_link = this.domain_link;
      const platform = this.getPlatform;
      if (value === domain_link) {
        this.setIsValidLink('error')
        return;
      }

      this.gTimer = setTimeout(() => {
        OrderAPI.validLink(value, platform)
            .then(res => res.data)
            .then(res => {this.setIsValidLink('success')})
            .catch(res => {this.setIsValidLink('error')});
      }, 500);
    },
    validEmail() {
      this.isValidEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(this.getEmail);
      this.setValidEmail(this.isValidEmail);
    },
    formatText() {
      return this.getQualityActivityDescription.replace(/\n/g, '<br>');
    },
    // eslint-disable-next-line max-statements
    handleSelectActivities(id) {
      const selectedQualityActivity = this.getQualityActivities?.find(item => item.id === id);
      this.setQualityActivity(id)
      this.setCount(selectedQualityActivity?.minAmount || 0);
      this.setParameterPriceDiff(0);
      const parameters = selectedQualityActivity.parameters
      parameters.forEach((item, key) => {
        item.isActive = key === 0;
        item.genders?.forEach((gender, key2) => {
          gender.isActive = key2 === 0
        })
      })


      if (parameters.length > 0) {
        const paramsCopy = [...parameters]
        const firstParameter = paramsCopy.shift()
        this.setCountries(JSON.parse(JSON.stringify(parameters)) || []);
        this.setCountry(firstParameter.value);


        if (firstParameter.genders.length > 0) {
          this.setGenders(JSON.parse(JSON.stringify(firstParameter.genders)).map((gender, keyGender) => {
            return  {
              ...gender,
              isActive: keyGender === 0,
              id: gender.value
            }
          }))
          // this.setGenders(JSON.parse(JSON.stringify(firstParameter.genders)))
          const firstGender = [...firstParameter.genders].shift()
          this.setGender(firstGender?.value)
          this.setParameterPriceDiff(parseFloat(firstGender?.price_diff) || 0);
        }

      } else {
        this.setCountries([]);
        this.setCountry(this.defaultValueCountries.value);
        this.setGenders([this.defaultValueGenders])
        this.setGender(this.defaultValueGenders.value)
      }



      if (this.getDefaultQualityActivities.id === id) {
        this.getQualityActivities?.forEach((item) => {
          item.isActive = false;
        });
        this.getDefaultQualityActivities.isActive = true;
        this.setCount(this.getDefaultQualityActivities?.minAmount || 50);
        if (this.getDefaultQualityActivities.parameters && this.getDefaultQualityActivities.parameters.length > 0) {
          this.setGenderValue(this.getDefaultQualityActivities.parameters[0])
        }
        this.setCostPerThousand(this.getDefaultQualityActivities?.price || 0);
        return;
      }
      this.getDefaultQualityActivities.isActive = false;

      this.getQualityActivities?.forEach((item) => {
        item.isActive = false;
      });
      const activity = this.getQualityActivities.find((item) => item.id === id);
      this.setQualityActivityDescription(activity.description);
      this.setCount(activity?.minAmount || 50);

      if (activity) {
        activity.isActive = true;
        this.setQualityActivity(activity.id);
        this.setCostPerThousand(activity.price);
      }
    },
    resetGenders() {
      this.setGenders([]);
      this.setGender(null);
    },

    setGenderValue(country = null) {
      console.log('setGenderValue', country)

      if (country) {
        this.setCountry(country.id);
        country.isActive = true;
        if (country.genders[0]) {
          const firstGenderPriceDiff = parseFloat(country.genders[0]?.price_diff) || 0;
          this.setParameterPriceDiff(firstGenderPriceDiff);
          // this.defaultValueGenders.value = country.genders[0].value;
          // this.defaultValueGenders.title = country.genders[0].title;
          // country.genders.shift();
        }
        const genders = country.genders.map((gender, keyGender) => ({
          ...gender,
          isActive: keyGender === 0,
          isDisabled: false,
          id: gender.value,
        }))
        this.setGenders(
            genders
        );
        this.setGender([...genders]?.shift()?.value)
      } else {
        this.setGender(this.defaultValueGenders.value);
      }
    },
    // eslint-disable-next-line max-statements
    handleSelectCountries(id) {
      this.setParameterPriceDiff(0);
      this.resetGenders();
      this.defaultValueGenders.isActive = true;
      if (this.defaultValueCountries.id === id) {
        this.getCountries?.forEach((item) => {
          item.isActive = false;
        });
        this.defaultValueCountries.isActive = true;
        this.setCountry(this.defaultValueCountries.id);
        this.setCountries([this.defaultValueCountries]);
        this.setGenders([this.defaultValueGenders].map(gender => {
          return {
            ...gender,
            isActive: true,
            id: gender.value
          }
        }));
        this.setGenderValue()
        this.setCountryIndex(1);
        this.setParameterPriceDiff(0);
        return;
      }


      this.defaultValueCountries.isActive = false;
      this.getCountries?.forEach((item) => {
        item.isActive = false;
      });
      const country = this.getCountries.find((item) => item.id === id);
      this.setGenderValue(country)
    },
    handleSelectGenders(id) {
      if (this.defaultValueGenders.isActive) {
        this.defaultValueGenders.isActive = false;
      }
      if (this.defaultValueGenders.id === id) {
        this.defaultValueGenders.isActive = true;
        this.setGender(this.defaultValueGenders.value);
      }
      this.getGenders?.forEach((item) => {
        item.isActive = false;
      });
      const gender = this.getGenders.find((item) => item.id === id);
      if (gender) {
        this.setGender(gender.id);

        const priceDiff = parseFloat(gender.price_diff, 2);
        if (priceDiff >= 0) {
          this.setParameterPriceDiff(priceDiff);
        } else {
          this.setParameterPriceDiff(0);
        }
        gender.isActive = true;
      }
    },
    handleShowModalEntry() {
      this.setMode(MODAL_MODES.entry);
      this.showModal();
    },
    setShowQuestion(value) {
      this.showQuestion = value;
    },
    ...mapMutations("order", [
      "setQualityActivity",
      "setQualityActivities",
      "setCountry",
      "setGender",
      "setCount",
      "setLink",
      "setEmail",
      "setMessage",
      "setCostPerThousand",
      "setGenders",
      "setCountryIndex",
      "setParameterPriceDiff",
      "setQualityActivityDescription",
      "setCountries",
      "setOrderSubmit",
      "setValidEmail",
      "setValidLink",
    ]),
    ...mapMutations("modal", ["showModal", "setMode"]),
  },
};
</script>

<style lang="scss">
@import "./order-parameters";
</style>
