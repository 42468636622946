<template>
  <div class="modal-registration">
    <div class="modal-registration-heading">
      <div class="modal-remember-title typography-menu _medium _big">Восстановление пароля</div>
    </div>
    <div class="modal-content">
      <form
        class="modal-registration-entry"
        data-modal-registration-refresh
        @submit.prevent="handleSubmit"
      >
        <p class="modal-remember-text typography-text">
          После нажатия кнопки Вам придёт письмо на почту с новым паролем, который Вы сможете
          сменить в настройках профиля.
        </p>
        <div class="modal-registration-entry__wrapper">
          <div
            class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
          >
            Ваш email
          </div>
          <div class="personal-settings-input__wrapper">
            <Input
              v-model="email"
              inputName="email"
              placeholder="example@babama.ru"
              class="personal-settings-input"
              type="email"
              hasValidation
              required
            />
          </div>
        </div>
        <div class="modal-registration-entry__buttons modal-remember__buttons">
          <button class="modal-registration-entry-button modal-remember__button" type="submit">
            <Icon class="modal-registration-entry-button__icon" name="refresh" />
            <span class="modal-registration-entry-button__text typography-menu _bold _middle">
              Восстановить
            </span>
          </button>
          <a
            href="#"
            class="modal-registration-entry__remember typography-menu _bold _middle"
            @click.prevent="setEntryModal"
          >
            Вернуться ко входу
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import Input from "@/components/input/Input";
import { MODAL_MODES } from "@/js/constants";
import { AuthAPI } from "@/api/auth";

export default {
  name: "ModalRemember",
  components: {
    Icon,
    Input,
  },
  data() {
    return {
      emailData: "",
      passwordData: "",
    };
  },
  computed: {
    email: {
      set(value) {
        this.emailData = value;
      },
      get() {
        return this.emailData;
      },
    },
  },
  methods: {
    setEntryModal() {
      this.setMode(MODAL_MODES.entry);
    },
    handleSubmit() {
      AuthAPI.forgotPassword(this.emailData).then((response) => {
        this.setMode(MODAL_MODES.notification);
        this.setNotificationContent({
          title: "Успех",
          content: "Мы направили новый пароль на Вашу почту. Если вдруг будут трудности или вопросы, то пишите в тех поддержку",
          callback: () => {
            setTimeout(() => {
              this.hideModal();
            }, 6000);
          },
        });
        this.showModal();
      });
    },
    ...mapMutations("modal", ["setMode", "hideModal", "setNotificationContent"]),
  },
};
</script>

<style lang="scss">
@import "./modal-remember.scss";
</style>
