<template>
  <section class="advantages">
    <div class="container advantages__container">
      <h2 class="advantages__title typography _medium">
        <span class="advantages__title-span advantages__title-span_sm">Три причины</span>
        <span class="advantages__title-span">Причины</span> быть с нами
      </h2>
      <p class="advantages__subtitle typography-menu _regular _middle">
        Для начала, Вы для нас люди, а не просто цифры на счету. Поэтому...
      </p>
      <ul class="advantages__list">
        <li class="advantages__item advantages-item">
          <div class="advantages-item__image" />
          <h3 class="advantages-item__title typography-heading _medium">
            Живые и&nbsp;не&nbsp;живые
          </h3>
          <p class="advantages-item__text typography-paragraph">
            Есть крайне простые, но при этом выгодные аккаунты для накрутки. А есть максимально
            качественные, лучше которых не найти на рынке (у всех либо такие же, либо хуже).
          </p>
        </li>
        <li class="advantages__item advantages-item">
          <div class="advantages-item__image" />
          <h3 class="advantages-item__title typography-heading _medium">
            Адекватная тех.&nbsp;поддержка
          </h3>
          <p class="advantages-item__text typography-paragraph">
            Нет, мы не всегда на связи. Зачем нам Вам врать? Но мы решаем абсолютно все вопросы и
            делаем это очень оперативно. Можете проверить сами, написав нам по любым контактам.
          </p>
        </li>
        <li class="advantages__item advantages-item">
          <div class="advantages-item__image" />
          <h3 class="advantages-item__title typography-heading _medium">Бесплатные активности</h3>
          <p class="advantages-item__text typography-paragraph">
            Каждый день мы выкладываем в свой телеграм канал бесплатные активности. Вы просто
            вводите промокод и получаете накрутку на свой аккаунт за свою лояльность.
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Picture from "@/components/picture/Picture";

export default {
  name: "Advantages",
  components: {
    Picture,
  },
};
</script>

<style lang="scss">
@import "./advantages.scss";
</style>
