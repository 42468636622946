<template>
  <div class="order-summary">
    <div class="order-summary__main">
      <h6 class="order-summary__subheading typography-heading _regular">Стоимость заказа</h6>
      <div class="order-summary__count typography _bold">
        <input
          name="order-summary"
          type="text"
          class="order-summary__input"
          :value="getTotalPrice"
        />
        <div class="order-summary__count-number">{{ formattedSummary }}</div>
        &nbsp;₽
      </div>
    </div>
    <button type="button"
      :class="[
        'order-summary__promo',
        'typography-menu',
        '_medium',
        '_middle',
        { _activated: isPromoActivated },
      ]"
      @click="handlePromocodeClick"
      :disabled="!getValidEmail"
    >
      <div v-if="!isPromoActivated" :class="['order-summary__promo', { _disable: !getValidEmail }]"
           @mouseover="setShowQuestion(true)"
           @mouseleave="setShowQuestion(false)"
           @click="setShowQuestion(!showQuestion)">
          Введите промокод
      </div>
        <div v-show="showQuestion && !getValidEmail" class="order-summary__promo _question__hover">
            <div class="order-summary__promo _question__hover_content" v-html="this.formatText()"/>
        </div>
      <div class="order-summary__promo-activated">
        <Icon name="check-promocode" class="order-summary__promo-icon" />
        Промокод принят
      </div>
    </button>
    <input name="promocode" class="order-summary__promo-input" type="text" />
    <button type="submit"  class="order-summary__submit" :disabled="!Boolean(getTotalPrice) || !getOrderSubmit">
      <Icon name="circle-check" class="order-summary__icon" />
      <span class="order-summary__submit-text typography-menu _bold _middle">Заказать</span>
    </button>
    <div class="order-summary__policy typography-menu _regular _small">
      Оплачивая заказ, Вы автоматически соглашаетесь с офертой и правилами использования нашего
      сервиса
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import { MODAL_MODES } from "@/js/constants";

export default {
  name: "OrderSummary",
  components: {
    Icon,
  },
  data() {
    return {
      showQuestion: false
    };
  },
  computed: {
    email: {
      set(value) {
        this.setEmail(value);
      },
      get() {
        return this.value
      }
    },
    link: {
      set(value) {
        this.setLink(value);
      },
      get() {
        return this.value;
      }
    },
    orderSubmit: {
      set(value) {
        this.setOrderSubmit(value);
      },
      get() {
        return this.getOrderSubmit;
      }
    },
    formattedSummary() {
      return this.getTotalPrice.toFixed(2);
    },
    ...mapGetters("order", [
      "getTotalPrice",
      "isPromoActivated",
      "getEmail",
      "getLink",
      "getCount",
      "getMessage",
      "getOrderSubmit",
      "getValidLink",
      "getValidEmail",
    ]),
  },
  methods: {
    handlePromocodeClick() {
      this.showModal();
      this.setMode(MODAL_MODES.promocodeInsert);
    },
    setShowQuestion(value) {
        this.showQuestion = value;
    },
    formatText() {
      return 'Для применения промокода Вам нужно зарегистрироваться или войти под своим емейлом в нашем сервисе.';
    },
    ...mapMutations("modal", ["showModal", "setMode"]),
    ...mapMutations("order", ["setEmail", "setLink", "setOrderSubmit"]),
  },
};
</script>

<style lang="scss">
@import "./order-summary";
</style>
