<template>
  <div :class="['modal', { _active: isVisible }]">
    <div class="modal-overlay" @click="handleClose" />
    <div class="modal-wrapper">
      <button class="modal-close" @click="handleClose">
        <Icon class="modal-close__icon" name="close" />
      </button>
      <ModalRegistration v-if="isRegistrationModal">
        <template #heading>
          <ModalRegistrationHeading />
        </template>
        <template #content>
          <ModalRegistrationEntry v-if="isEntryMode" />
          <ModalRegistrationReg v-if="isRegistrationMode" />
        </template>
      </ModalRegistration>
      <ModalRemember v-if="isModalRemember" />
      <ModalOrder v-if="isModalOrder" />
      <ModalThanks v-if="isModalThanks" />
      <ModalPromocode v-if="isModalPromocode" />
      <ModalQuestion v-if="isModalQuestion" />
      <ModalDestination v-if="isModalDestination" />
      <ModalNotification v-if="isModalNotification" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import ModalRegistration from "@/components/modal-registration/ModalRegistration";
import ModalRegistrationHeading from "@/components/modal-registration-heading/ModalRegistrationHeading";
import ModalRegistrationEntry from "@/components/modal-registration-entry/ModalRegistrationEntry";
import ModalRegistrationReg from "@/components/modal-registration-reg/ModalRegistrationReg";
import ModalRemember from "@/components/modal-remember/ModalRemember";
import ModalOrder from "@/components/modal-order/ModalOrder";
import ModalThanks from "@/components/modal-thanks/ModalThanks";
import ModalPromocode from "@/components/modal-promocode/ModalPromocode";
import ModalQuestion from "@/components/modal-question/ModalQuestion";
import ModalDestination from "@/components/modal-destination/ModalDestination";
import ModalNotification from "@/components/modal-notification/ModalNotification";

export default {
  name: "Modal",
  components: {
    Icon,
    ModalRegistration,
    ModalRegistrationHeading,
    ModalRegistrationEntry,
    ModalRegistrationReg,
    ModalRemember,
    ModalOrder,
    ModalThanks,
    ModalPromocode,
    ModalQuestion,
    ModalDestination,
    ModalNotification,
  },
  computed: {
    isModalPromocode() {
      return (
        this.isModalPromocodeInsert || this.isModalPromocodeAccept || this.isModalPromocodeReject
      );
    },
    isModalThanks() {
      return (
        this.isModalThanksOrderNoReg ||
        this.isModalThanksOrderHasBalance ||
        this.isModalThanksOrderNoBalance ||
        this.isModalThanksQuestion ||
        this.isModalThanksAfterRefillBalance ||
        this.isModalThanksAfterPay
      );
    },
    ...mapGetters("modal", [
      "isVisible",
      "isRegistrationModal",
      "isRegistrationMode",
      "isEntryMode",
      "isModalRemember",
      "isModalOrder",
      "isModalThanksOrderNoReg",
      "isModalThanksOrderHasBalance",
      "isModalThanksOrderNoBalance",
      "isModalThanksAfterPay",
      "isModalThanksQuestion",
      "isModalPromocodeInsert",
      "isModalPromocodeAccept",
      "isModalPromocodeReject",
      "isModalQuestion",
      "isModalDestination",
      "isModalNotification",
      "isModalThanksAfterRefillBalance",
    ]),
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleClose() {
      if (
          this.isModalThanksOrderNoReg
          || this.isModalThanksOrderHasBalance
          || this.isModalThanksOrderNoBalance
          || this.isModalThanksAfterPay
          || this.isModalOrder
      ) {
        this.resetState()
          
        setTimeout(() => {
            this.$store.dispatch('user/getUserData')
        }, 600);
      }

      this.hideModal();

    },
    handleKeyDown(event) {
      if (this.isVisible && event.keyCode === 27) {
        this.handleClose();
      }
    },
    ...mapMutations("modal", ["hideModal"]),
    ...mapMutations("order", ["resetState"]),
  },
};
</script>

<style lang="scss">
@import "./modal.scss";
</style>
