import { DefaultAPIInstance } from "@/api";

export const FaqAPI = {
  getTickets() {
    const url = "/top-questions";
    return DefaultAPIInstance.get(url);
  },

  sendTicket(data) {
    const url = "/user/tickets";
    return DefaultAPIInstance.post(url, data);
  },
};
