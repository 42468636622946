<template>
  <form class="modal-registration-entry" @submit.prevent="handleSubmit">
    <div class="modal-registration-entry__wrapper">
      <div
        class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
      >
        Ваш email
      </div>
      <div class="personal-settings-input__wrapper">
        <Input
          v-model="email"
          inputName="email"
          placeholder="example@babama.ru"
          class="personal-settings-input"
          hasValidation
          type="email"
          required
        />
      </div>
    </div>
    <div class="modal-registration-entry__wrapper">
      <div
        class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
      >
        Ваш пароль
      </div>
      <InputPassword v-model="password" inputName="password" placeholder="Password12345678" />
        <div class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular _error"> {{ error }}</div>
    </div>
    <div class="modal-registration-entry__buttons">
      <button class="modal-registration-entry-button" data-modal-registration-enter type="submit">
        <Icon class="modal-registration-entry-button__icon" name="entry" />
        <span class="modal-registration-entry-button__text typography-menu _bold _middle">
          Войти
        </span>
      </button>
      <a
        href="#"
        class="modal-registration-entry__remember typography-menu _bold _middle"
        @click.prevent="setRememberModal"
      >
        Забыли пароль?
      </a>
    </div>
  </form>
</template>

<script>
import { mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import InputPassword from "@/components/input-password/InputPassword";
import Input from "@/components/input/Input";
import { MODAL_MODES } from "@/js/constants";

export default {
  name: "ModalRegistrationEntry",
  components: {
    Icon,
    InputPassword,
    Input,
  },
  data() {
    return {
      emailData: "",
      passwordData: "",
      errorData: "",
    };
  },
  computed: {
    email: {
      set(value) {
        this.emailData = value;
      },
      get() {
        return this.emailData;
      },
    },
    password: {
      set(value) {
        this.passwordData = value;
      },
      get() {
        return this.passwordData;
      },
    },
    error: {
      set(value) {
        this.errorData = value;
      },
      get() {
        return this.errorData;
      },
    },
  },
  methods: {
    setRememberModal() {
      this.setMode(MODAL_MODES.remember);
    },
    handleSubmit() {
      this.$store.dispatch("user/onLogin", {
        email: this.emailData,
        password: this.passwordData,
        onSuccess: () => {
          this.hideModal();
        },
        onError: (response) => {
            this.error = "Неверный пароль";
        },
      });
    },
    ...mapMutations("modal", ["setMode", "hideModal", "setNotificationContent"]),
  },
};
</script>
