import axios from "axios";
import Vue from "vue";

const loginConfig = {
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
};

export const LoginAPIInstance = axios.create(loginConfig);

const defaultConfig = {
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
};

const token = localStorage.getItem("token");
if (token) {
  defaultConfig.headers.Authorization = `Bearer ${token}`;
}

export const DefaultAPIInstance = axios.create(defaultConfig);

DefaultAPIInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 401) {
      localStorage.removeItem("token");
      location.reload();
    }

    if (error.response.status !== 401) {
      Vue.notify({
        group: "foo",
        title: "Ошибка",
        type: "error",
        text: error.response.data.message,
        width: "500px",
      });
    }

    return Promise.reject(error);
  }
);
