<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer-logo">
        <router-link
          to="/"
          :class="['footer-logo__link', { _disabled: $route.name === 'home' }]"
          data-logo
        >
          <Icon name="logo" class="footer-logo__icon" />
        </router-link>
        <p class="footer-logo__text typography-menu _medium _middle">
          Реальные отзывы о&nbsp;нас&nbsp;на&nbsp;IN-SCALE
        </p>
      </div>
      <div class="footer-warning">
        <p class="footer-warning__text typography-paragraph _small">
          *Компания Meta, которой принадлежит<br />
          Instagram и Facebook, признана в России<br />
          экстремистской организацией.
        </p>
      </div>
      <div class="footer-feedback">
        <div class="footer-feedback__subtitle typography-menu _regular _middle">задать вопрос</div>
        <a href="mailto:info@babama.ru" class="footer-feedback__email typography-display _medium"
          >info@babama.ru</a
        >
        <div class="footer-feedback__buttons">
          <button class="footer-feedback__button footer-feedback__item" @click="handleClick">
            <Icon name="question" class="footer-feedback__icon" />
            Задать вопрос
          </button>
          <a href="#" class="footer-feedback__link footer-feedback__item">
            <Icon name="telegram" class="footer-feedback__icon" />
            Новости и промокоды
          </a>
        </div>
      </div>
      <nav class="footer-menu">
        <ul class="footer-menu__list typography-menu _middle">
          <li v-for="page in getStaticPages" :key="page.id" class="footer-menu__item">
            <router-link :to="{ name: page.slug }" class="footer-menu__link" data-footer-link
              >{{ page.title }}
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="footer-copyright typography-menu _regular _middle">
        <span>© 2015-{{ currentYear }}</span> <br />
        <span> Сервис продвижения в соцсетях Babama</span>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import { MODAL_MODES } from "@/js/constants";

export default {
  name: "Footer",
  components: {
    Icon,
  },
  computed: {
    currentYear() {
      const date = new Date();
      return date.getFullYear();
    },
    getStaticPages() {
      return this.getPages().data;
    },
  },
  mounted() {
    this.loadPages();
  },
  methods: {
    handleClick() {
      this.setMode(MODAL_MODES.destination);
      this.showModal();
    },
    ...mapMutations("modal", ["setMode", "showModal"]),
    ...mapGetters("staticPages", ["getPages"]),
    ...mapActions("staticPages", ["loadPages"]),
  },
};
</script>

<style lang="scss">
@import "./footer.scss";
</style>
