<template>
  <div v-show="needShow" class="content-header">
    <div class="content-header-tabs">
      <router-link activeClass="_active" to="/" class="content-header-tab" exact>
        <Icon class="content-header-tab__icon" name="fire-fill" />
        <div class="content-header-tab__title typography-menu _medium _small">Новый заказ</div>
      </router-link>
      <router-link
        v-show="isUserAuthed"
        activeClass="_active"
        to="/history"
        class="content-header-tab"
      >
        <Icon class="content-header-tab__icon" name="paper" />
        <div class="content-header-tab__title typography-menu _medium _small">
          История<span class="content-header-tab__title-md"> заказов</span>
        </div>
      </router-link>
    </div>
    <div v-if="currentPathName !== 'faq'" class="content-header-buttons">
      <router-link to="/faq" class="content-header-button typography-menu _bold _small">
        <Icon class="content-header-button__icon" name="question" />
        <div class="content-header-button__text">Помощь</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Icon from "@/components/icon/Icon";

export default {
  name: "ContentHeader",
  components: {
    Icon,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: "order",
    },
  },
  computed: {
    currentPathName() {
      return this.$route.name;
    },
    needShow() {
      return ![
        "personal-settings",
        "fund-history",
        "personal-wallet",
        "not-found",
        "payment-succeed",
        "payment-failed",
      ].includes(this.$route.name);
    },
    ...mapGetters("user", ["isUserAuthed"]),
  },
};
</script>

<style lang="scss">
@import "./content-header.scss";
</style>
