import { StaticPagesAPI } from "@/api/static-pages";

const staticPagesState = {
  pages: [],
};

const actions = {
  loadPages({ commit }) {
    StaticPagesAPI.getPages().then((res) => {
      commit("setPages", res.data);
    });
  },
};

const mutations = {
  setPages(state, data) {
    state.pages = data;
  },
};

const getters = {
  getPages(state) {
    return state.pages;
  },
};

const store = {
  namespaced: true,
  state: staticPagesState,
  actions,
  mutations,
  getters,
};

export default store;
