<template>
  <input
    type="email"
    :name="inputName"
    class="input-email typography-menu _medium _small"
    :placeholder="placeholder"
    :value="value"
    @input="handleInput"
  />
</template>

<script>
import Icon from "@/components/icon/Icon";
export default {
    name: "InputEmail",
    components: {
      Icon
    },
    props: {
        inputName: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        placeholder: {
            type: String,
            required: false,
            default: "example@babama.ru",
        },
        value: {
            type: String,
            default: "",
            required: false,
        },
    },
    data: () => ({ email: 'example@babama.ru' }),
    computed: {},
    methods: {
        handleInput(event) {
          this.$emit("input", event.target.value);
        },
  },
};
</script>

<style lang="scss">
@import "./input-email.scss";
</style>
