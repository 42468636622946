import { DefaultAPIInstance } from "@/api";

export const OrderAPI = {
  getPlatforms() {
    const url = "/categories";
    return DefaultAPIInstance.get(url);
  },

  getPaymentSystems() {
    const url = "/payment-systems";
    return DefaultAPIInstance.get(url);
  },

  getUserFundHistory() {
    const url = "/my-fund-history";
    return DefaultAPIInstance.get(url);
  },

  getPaymentLink(data) {
    const amount  = Math.round(parseFloat(data.amount) * 100) / 100;
    const system = data.platform;
    const orderId = data.order_id || '';
    const url = "/get-payment-link";
    return DefaultAPIInstance.get(`${url}?system=${system}&amount=${amount}&order_id=${orderId}`);
  },

  getPaymentLinkGuest(data) {
      const amount  = Math.round(parseFloat(data.amount) * 100) / 100;
      const system = data.platform;
      const email = data.email;
      const orderId = data.order_id || '';
      const url = "/get-payment-link-email";
      return DefaultAPIInstance.get(`${url}?system=${system}&amount=${amount}&email=${email}&order_id=${orderId}`);
  },

  getLastUnpaidOrder() {
    return DefaultAPIInstance.get("last-unpaid-order");
  },

  validatePromocode(promocodeValue, emailValue, activityId) {
    const data = {
      code: promocodeValue,
      email: emailValue,
      ...(activityId ? { activity_id: activityId } : {}),
    };

    const url = "/promo-code/checkout";
    return DefaultAPIInstance.post(`${url}`, data);
  },

  getActivities(id) {
    const url = `/category/${id}/activities`;
    return DefaultAPIInstance.get(url);
  },

  getOrderHistory() {
    const url = `/my-orders`;
    return DefaultAPIInstance.get(url);
  },

  async getParams(categoryId, activityId) {
    const url = `/category/${categoryId}/activity/${activityId}/services`;
    return await DefaultAPIInstance.get(url);
  },

  postOrder(data) {
    const url = `/order`; //?category=3&service=4&link=1231&quantity=85
    return DefaultAPIInstance.post(url, data);
  },

  postOrderUnauthenticated(data) {
    const url = `/order-unauthenticated`; //?category=3&service=4&link=1231&quantity=85
    return DefaultAPIInstance.post(url, data);
  },

  repeatOrder(id) {
    const url = `/repeat-order`;
    return DefaultAPIInstance.post(`${url}/${id}`);
  },

  repayOrder(id) {
    const url = `/repay-order`;
    return DefaultAPIInstance.post(`${url}/${id}`);
  },

  validLink(link, category) {
    const url = `/valid-link/${category}?link=${link}`;
    return DefaultAPIInstance.get(url);
  },

  getTransactionByUuid(uuId) {
    const url = `/transactions/${uuId}`;
    return DefaultAPIInstance.get(url);
  },
  
  checkUserStatus(uuId) {
    const url = `/transactions/${uuId}/check_user_status`;
    return DefaultAPIInstance.get(url);
  }
};
