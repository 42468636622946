<template>
  <div class="profile" data-profile>
    <router-link to="/personal-settings" class="profile-item" data-personal-content-settings>
      <div class="profile__icon-wrapper">
        <Icon name="background" class="profile__icon-background" />
        <Icon name="user-circle" class="profile__icon" />
      </div>
      <span class="profile-login__text typography-menu _bold _middle">{{ getUserEmail }}</span>
    </router-link>
    <div class="profile-devider" />
    <router-link
      to="/personal-wallet"
      class="profile-balance profile-item typography-menu _regular _middle"
      data-personal-content-wallet
    >
      <div class="profile-balance__icon-wrapper profile__icon-wrapper">
        <Icon name="background" class="profile__icon-background" />
        <Icon name="wallet" class="profile__icon" />
      </div>
      <div class="profile-balance__text">
        Ваш баланс:
        <span
          class="typography-menu _medium _middle profile-balance__currency"
          data-profile-currency="140"
          >{{ getUserBalance }} ₽</span
        >
      </div>
      <div class="profile-balance__link">Пополнить кошелек</div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Icon from "@/components/icon/Icon";

export default {
  name: "Profile",
  components: {
    Icon,
  },
  computed: {
    getUserBalance() {
      return this.getBalance.toFixed(2);
    },
    ...mapGetters("user", ["getBalance", "getUserEmail"]),
  },
};
</script>

<style lang="scss">
@import "./profile.scss";
</style>
