<template>
  <div class="personal-settings-input__wrapper">
    <input
      ref="input"
      v-model="input"
      :class="[
        'personal-settings-input input typography-menu _medium _small personal-settings-password',
        className,
        { _focused: showButtons },
      ]"
      :name="inputName"
      :placeholder="placeholder"
      required
      :type="inputType"
      minlength="6"
    />
    <div class="personal-settings-input__buttons">
      <button
        v-if="hasButtonClean"
        class="personal-settings-input__button personal-settings-input__clean"
        type="button"
        data-input-button
      >
        <Icon class="personal-settings-input__icon" name="clean" />
      </button>
      <button
        class="personal-settings-input__button personal-settings-input__show"
        type="button"
        data-input-button
        @click.capture="handleShow"
      >
        <Icon class="personal-settings-input__icon" name="show" />
      </button>
      <button
        class="personal-settings-input__button personal-settings-input__hide"
        type="button"
        data-input-button
        @click.capture="handleHide"
      >
        <Icon class="personal-settings-input__icon" name="hide" />
      </button>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon";

export default {
  name: "InputPassword",
  components: {
    Icon,
  },
  props: {
    hasButtonClean: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    className: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      showButtons: false,
      inputType: "password",
    };
  },
  computed: {
    input: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  mounted() {
    this.$refs.input.addEventListener("focus", this.handleFocus);
    this.$refs.input.addEventListener("blur", this.handleBlur);
  },
  beforeDestroy() {
    this.$refs.input.removeEventListener("focus", this.handleFocus);
    this.$refs.input.removeEventListener("blur", this.handleBlur);
  },
  methods: {
    handleFocus() {
      this.showButtons = true;
    },
    handleBlur(event) {
      if (
        !event.relatedTarget ||
        (event.relatedTarget && !event.relatedTarget.hasAttribute("data-input-button"))
      ) {
        this.showButtons = false;
      }
    },
    handleShow() {
      this.inputType = "text";
      this.$refs.input.focus();
      setTimeout(() => {
        this.$refs.input.setSelectionRange(this.value.length, this.value.length);
      }, 0);
    },
    handleHide() {
      this.inputType = "password";
      this.$refs.input.focus();
      setTimeout(() => {
        this.$refs.input.setSelectionRange(this.value.length, this.value.length);
      }, 0);
    },
  },
};
</script>
<style lang="scss">
@import "../personal-settings/personal-settings.scss";
</style>
