var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-summary"},[_c('div',{staticClass:"order-summary__main"},[_c('h6',{staticClass:"order-summary__subheading typography-heading _regular"},[_vm._v("Стоимость заказа")]),_c('div',{staticClass:"order-summary__count typography _bold"},[_c('input',{staticClass:"order-summary__input",attrs:{"name":"order-summary","type":"text"},domProps:{"value":_vm.getTotalPrice}}),_c('div',{staticClass:"order-summary__count-number"},[_vm._v(_vm._s(_vm.formattedSummary))]),_vm._v("  ₽ ")])]),_c('button',{class:[
      'order-summary__promo',
      'typography-menu',
      '_medium',
      '_middle',
      { _activated: _vm.isPromoActivated },
    ],attrs:{"type":"button","disabled":!_vm.getValidEmail},on:{"click":_vm.handlePromocodeClick}},[(!_vm.isPromoActivated)?_c('div',{class:['order-summary__promo', { _disable: !_vm.getValidEmail }],on:{"mouseover":function($event){return _vm.setShowQuestion(true)},"mouseleave":function($event){return _vm.setShowQuestion(false)},"click":function($event){return _vm.setShowQuestion(!_vm.showQuestion)}}},[_vm._v(" Введите промокод ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showQuestion && !_vm.getValidEmail),expression:"showQuestion && !getValidEmail"}],staticClass:"order-summary__promo _question__hover"},[_c('div',{staticClass:"order-summary__promo _question__hover_content",domProps:{"innerHTML":_vm._s(this.formatText())}})]),_c('div',{staticClass:"order-summary__promo-activated"},[_c('Icon',{staticClass:"order-summary__promo-icon",attrs:{"name":"check-promocode"}}),_vm._v(" Промокод принят ")],1)]),_c('input',{staticClass:"order-summary__promo-input",attrs:{"name":"promocode","type":"text"}}),_c('button',{staticClass:"order-summary__submit",attrs:{"type":"submit","disabled":!Boolean(_vm.getTotalPrice) || !_vm.getOrderSubmit}},[_c('Icon',{staticClass:"order-summary__icon",attrs:{"name":"circle-check"}}),_c('span',{staticClass:"order-summary__submit-text typography-menu _bold _middle"},[_vm._v("Заказать")])],1),_c('div',{staticClass:"order-summary__policy typography-menu _regular _small"},[_vm._v(" Оплачивая заказ, Вы автоматически соглашаетесь с офертой и правилами использования нашего сервиса ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }