import { MODAL_MODES } from "@/js/constants";

const modalState = {
  isVisible: false,
  mode: MODAL_MODES.reginstration,
  notificationContent: {
    title: "",
    content: "",
    // eslint-disable-next-line no-empty-function
    callback: () => {},
  },
};

const mutations = {
  showModal(state) {
    state.isVisible = true;
  },
  hideModal(state) {
    state.isVisible = false;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setNotificationContent(state, content) {
    state.notificationContent = content;
  },
};

const getters = {
  isVisible(state) {
    return state.isVisible;
  },
  getMode(state) {
    return state.mode;
  },
  isRegistrationModal(state) {
    return [MODAL_MODES.reginstration, MODAL_MODES.entry].includes(state.mode);
  },
  isEntryMode(state) {
    return state.mode === MODAL_MODES.entry;
  },
  isRegistrationMode(state) {
    return state.mode === MODAL_MODES.reginstration;
  },
  isModalRemember(state) {
    return state.mode === MODAL_MODES.remember;
  },
  isModalOrder(state) {
    return state.mode === MODAL_MODES.order;
  },
  isModalThanksOrderNoReg(state) {
    return state.mode === MODAL_MODES.thanksOrderNoReg;
  },
  isModalThanksAfterRefillBalance(state) {
    return state.mode === MODAL_MODES.thanksAfterRefillBalance;
  },
  isModalThanksOrderHasBalance(state) {
    return state.mode === MODAL_MODES.thanksOrderHasBalance;
  },
  isModalThanksOrderNoBalance(state) {
    return state.mode === MODAL_MODES.thanksOrderNoBalance;
  },
  isModalThanksAfterPay(state) {
    return state.mode === MODAL_MODES.thanksAfterPay;
  },
  isModalThanksQuestion(state) {
    return state.mode === MODAL_MODES.thanksQuestion;
  },
  isModalPromocodeInsert(state) {
    return state.mode === MODAL_MODES.promocodeInsert;
  },
  isModalPromocodeAccept(state) {
    return state.mode === MODAL_MODES.promocodeAccept;
  },
  isModalPromocodeReject(state) {
    return state.mode === MODAL_MODES.promocodeReject;
  },
  isModalQuestion(state) {
    return state.mode === MODAL_MODES.question;
  },
  isModalDestination(state) {
    return state.mode === MODAL_MODES.destination;
  },
  isModalNotification(state) {
    return state.mode === MODAL_MODES.notification;
  },
  getNotificationContent(state) {
    return state.notificationContent;
  },
};

const store = {
  namespaced: true,
  state: modalState,
  mutations,
  getters,
};

export default store;
