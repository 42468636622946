<template>
  <div class="select relative typography-menu _medium">
    <select :name="selectName" class="select-native">
      <option v-for="item in items" :key="item.id" :value="item.value" :selected="item.isActive">
        {{ item.title }}
      </option>
    </select>
    <div ref="select-custom" :class="getSelectClass">
      <div
        ref="select-custom-trigger"
        :class="['select-custom-trigger', { _selected: !getActiveOption?.isDisabled }]"
      >
        <img v-if="getActiveOption?.icon" :src="getActiveOption?.icon" alt="" class="select-icon" />
        {{ getActiveOption?.title }}
      </div>
      <div class="select-custom-options">
        <SelectOption
            v-for="item in items"
            :key="item.id"
            v-bind="item"
            @handleSelect="handleSelect(item.id)"
        />
        <SelectOption
          v-if="defaultValue && !defaultValue.isDisabled"
          v-bind="defaultValue"
          @handleSelect="handleSelect(defaultValue.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectOption from "./SelectOption";

export default {
  name: "Select",
  components: {
    SelectOption,
  },
  props: {
    selectName: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: Object,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    getSelectClass() {
      return [
        "select-custom",
        { _selected: this.defaultValue && !this.defaultValue?.isDisabled },
        { isActive: this.isOpened },
      ];
    },
    getActiveOption() {
      if (this.defaultValue && this.defaultValue.isActive) {
        return this.defaultValue;
      }
      const option = this.items.find((item) => item.isActive);
      return option || this.defaultValue;
    },
  },
  mounted() {
    this.$refs["select-custom-trigger"].addEventListener("click", this.toggleSelect);
  },

  beforeDestroy() {
    this.$refs["select-custom-trigger"].removeEventListener("click", this.toggleSelect);
  },

  methods: {
    getIcon(icon) {
      return icon ? `${process.env.VUE_APP_BASE_URL}/${icon}` : "./images/default.png";
    },
    getOptionClass(item) {
      return [
        "select-custom-option",
        { _disabled: item?.isDisabled },
        { isActive: item?.isActive },
      ];
    },
    toggleSelect() {
      if (this.isOpened) {
        this.closeSelectCustom();
      } else {
        this.openSelectCustom();
      }
    },
    closeSelectCustom() {
      this.isOpened = false;

      document.removeEventListener("click", this.watchClickOutside);
      document.removeEventListener("keydown", this.watchEscape);
    },
    openSelectCustom() {
      this.isOpened = true;

      document.addEventListener("click", this.watchClickOutside);
      document.addEventListener("keydown", this.watchEscape);
    },
    watchEscape(event) {
      if (event.key === "Escape") {
        this.closeSelectCustom();
      }
    },
    watchClickOutside(event) {
      const didClickedOutside = event.target ? !this.$refs["select-custom"]?.contains(event.target) : true;
      if (didClickedOutside) {
        this.closeSelectCustom();
      }
    },
    handleSelect(id) {
      this.$emit("handleSelect", id);
      this.closeSelectCustom();
    },
  },
};
</script>

<style lang="scss">
@import "./select.scss";
</style>
