<template>
  <div class="personal-wallet-group">
    <div class="order-parameters__item-heading typography-menu _regular _middle">
      <span class="order-parameters__item-title">Сумма пополнения</span>
    </div>
    <div class="personal-wallet-input__wrapper">
      <input
        ref="input"
        v-model="inputAmount"
        name="amount"
        placeholder="1000 ₽"
        class="personal-wallet-input input typography-menu _medium _small"
        required
      />
      <button
        class="personal-wallet-input__clean"
        type="button"
        data-personal-wallet-amount-clean
        @click="handleClean"
      >
        <Icon class="personal-wallet-input__clean-icon" name="clean" />
      </button>
    </div>
    <ul class="personal-wallet-group__shorts" data-personal-wallet-shortcuts>
      <li
        v-for="(amount, index) in amounts"
        :key="index"
        class="personal-wallet-group__shorts-item"
      >
        <button
          class="personal-wallet-group__shorts-button typography-menu _medium _middle"
          type="button"
          @click="insertAmount(amount)"
        >
          {{ amount }} ₽
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { isNumber } from "eslint-plugin-unicorn/rules/utils/numeric";
import { mapGetters } from "vuex";

import Icon from "@/components/icon/Icon";

export default {
  name: "RefilWallet",
  components: {
    Icon,
  },
  props: {
    defaultValue: {
      type: Number,
    },
  },
  data() {
    return {
      amounts: [100, 250, 500, 1000],
      value: "",
    };
  },
  computed: {
    inputAmount: {
      set(value) {
        this.value = typeof value === "number" ? Math.ceil(value) : value?.replace(/\D/g, "");
      },
      get() {
        return this.value;
      },
    },
  },
  mounted() {
    this.inputAmount = this.defaultValue;
    this.$refs.input.addEventListener("focus", this.handleFocus);
    this.$refs.input.addEventListener("blur", this.handleBlur);
  },
  beforeDestroy() {
    this.$refs.input.removeEventListener("focus", this.handleFocus);
    this.$refs.input.removeEventListener("blur", this.handleBlur);
  },
  methods: {
    insertAmount(value) {
      this.value = `${value} ₽`;
    },
    handleFocus() {
      this.value = this.value?.replace(/\D/g, "");
    },
    handleBlur() {
      if (this.value === "") {
        this.value = "";
        return;
      }
      this.value = `${this.value} ₽`;
    },
    handleClean() {
      this.value = "";
    },
  },
};
</script>
<style lang="scss">
@import "../personal-wallet/personal-wallet.scss";
</style>
