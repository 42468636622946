<template>
  <div class="modal-registration modal-promocode" data-modal-promocode>
    <div class="modal-registration-heading">
      <div v-if="isModalPromocodeInsert" class="modal-remember-title typography-menu _medium _big">
        Активация промокода
      </div>
      <div v-if="isModalPromocodeAccept" class="modal-remember-title typography-menu _medium _big">
        Ваш промокод активирован
      </div>
      <div v-if="isModalPromocodeReject" class="modal-remember-title typography-menu _medium _big">
        Промокод отклонен
      </div>
    </div>
    <form class="modal-content" @submit.prevent="handleActivatePromocode">
      <div v-if="isModalPromocodeInsert" class="modal-registration-entry">
        <div class="modal-registration-entry__wrapper">
          <div
            class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
          />
          <div class="personal-settings-input__wrapper">
            <Input
              v-model="input"
              inputName="promocode"
              placeholder="********"
              class="typography-menu _medium _big"
            />
            <button
                v-if="isModalPromocodeReject"
                type="button"
                class="input-button"
            >
                <Icon class="input-button__icon" name="input-fail"/>
              </button>
          </div>
        </div>
        <div class="modal-registration-entry__buttons modal-remember__buttons">
          <button
            class="modal-registration-entry-button modal-promocode__button modal-remember__button typography-menu _bold _middle"
            type="submit"
          >
            Активировать
          </button>
        </div>
      </div>
      <div v-if="!isModalPromocodeInsert" class="modal-registration-entry">
        <p class="modal-remember-text typography-text">
          {{ getPromocodeText }}
        </p>

        <div class="modal-registration-entry__buttons modal-remember__buttons">
          <button
            class="modal-promocode__button modal-promocode__button_return typography-menu _bold _middle"
            type="button"
            @click="hideModal"
          >
            Вернуться на страницу оплаты
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Input from "@/components/input/Input";
import Icon from "@/components/icon/Icon";

export default {
  name: "ModalPromocode",
  components: {
    Input,
    Icon,
  },
  data() {
    return {
      value: "",
    };
  },
  computed: {
    input: {
      set(value) {
        this.value = value;
      },
      get() {
        return this.value;
      },
    },
    ...mapGetters("modal", [
      "isModalPromocodeInsert",
      "isModalPromocodeAccept",
      "isModalPromocodeReject",
    ]),
    ...mapGetters("order", ["getPromocodeText", "getEmail"]),
  },
  methods: {
    handleActivatePromocode() {
      const data = {
          email: this.getEmail,
          code: this.value,
      };

      this.$store.dispatch("order/setPromocode", data);
          // .finally(() => {
          //     this.value = ""
          // });
    },
    ...mapMutations("modal", ["hideModal"]),
  },
};
</script>

<style lang="scss">
@import "./modal-promocode.scss";
</style>
