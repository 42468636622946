<template>
  <div class="order" data-order data-page-content>
    <form class="order-form" @submit.prevent="handleSubmit">
      <div class="order-step">
        <div class="order-step__heading">
          <span class="order-step__subtitle typography-menu _regular _small">Шаг 1</span>
          <h2 class="order-step__title typography-heading _medium">Площадка</h2>
        </div>
        <OrderPlatform/>
      </div>
      <div :class="['order-step', { _disabled: !isActivitiesAvailable }]">
        <div class="order-step__heading">
          <span class="order-step__subtitle typography-menu _regular _small">Шаг 2</span>
          <h2 class="order-step__title typography-heading _medium">Активности</h2>
        </div>
        <OrderActivity/>
      </div>
      <div :class="['order-step', '_big', { _disabled: !isParamsAvailable }]">
        <div class="order-step__heading">
          <span class="order-step__subtitle typography-menu _regular _small">Шаг 3</span>
          <h2 class="order-step__title typography-heading _medium">Параметры</h2>
        </div>
        <OrderParameters/>
        <OrderSummary/>
      </div>
    </form>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import OrderPlatform from "@/components/order-platform/OrderPlatform";
import OrderActivity from "@/components/order-activity/OrderActivity";
import OrderParameters from "@/components/order-parameters/OrderParameters";
import OrderSummary from "@/components/order-summary/OrderSummary";
import {OrderAPI} from "@/api/order";
import {MODAL_MODES} from "@/js/constants";
import { DefaultAPIInstance } from "@/api";

export default {
  name: "Order",
  components: {
    OrderPlatform,
    OrderActivity,
    OrderParameters,
    OrderSummary,
  },
  computed: {
    ...mapGetters("order", [
      "isParamsAvailable",
      "getTotalPrice",
      "isActivitiesAvailable",
      "getLastUnpaidOrder",
      "getOrderId",
      "getPromocodeCode",
      "getOrderSubmit",
    ]),
    ...mapGetters("user", ["isUserAuthed", "getBalance", "getUserEmail", "getUser"]),
    ...mapGetters("modal", [
      "getMode",
      "isModalThanksOrderNoReg",
      "isModalThanksOrderHasBalance",
      "isModalThanksOrderNoBalance",
      "isModalThanksQuestion",
    ]),
  },
  mounted() {
    this.$store.dispatch("order/initOrderParams");
    this.$store.dispatch("order/initPlatforms");
    if (this.getLastUnpaidOrder) {
      this.setParametersFromOrder(this.getLastUnpaidOrder);
      this.setMode(MODAL_MODES.order);
      this.showModal();
    }
  },
  methods: {
    handleSubmit() {
      if (this.isUserAuthed) {
        if (this.getBalance >= this.getTotalPrice) {
          this.setMode(MODAL_MODES.thanksOrderHasBalance);
        } else {
          this.setMode(MODAL_MODES.thanksOrderNoBalance);
        }

        this.$store.dispatch("order/sendOrder")
          .then(res => res.data)
          .then(res => {
            this.setOrderId(res.order_id);
            this.showModal();
          })
      } else {
        this.setMode(MODAL_MODES.order);

        this.$store.dispatch("order/sendOrderUnauthenticated")
          .then(res => res.data)
          .then(async res => {
            this.setOrderId(res.order_id);
            this.showModal();

            if (res.token) {
              await this.deleteToken();
              await this.setToken(res.token);
              // await setTimeout(() => {
              //   this.$store.dispatch("user/getUserData")
              // }, 700)

              DefaultAPIInstance.defaults.headers.Authorization = `Bearer ${res.token}`;
            }
          })
      }
    },
    setParametersFromOrder(order) {
      this.$store.dispatch("order/setActivity", order.activity);
      this.$store.dispatch("order/setPlatform", order.category);
      this.setPlatform(order.category);
      this.setActivity(order.activity);
      this.setQualityActivity(order.service);
      this.setLink(order.link);
    },
    ...mapMutations("modal", ["showModal", "setMode"]),
    ...mapMutations("user", ["setToken", "setUser", "deleteToken"]),
    ...mapMutations("order", [
      "setActivity",
      "setLink",
      "setActivities",
      "setCostPerThousand",
      "setQualityActivity",
      "setPlatform",
      "setQualityActivities",
      "setOrderId",
    ]),
    ...mapActions("order", ["initParams"]),
  },
};
</script>

<style lang="scss">
@import "./order.scss";
</style>
