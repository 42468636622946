<template>
  <div class="modal-registration modal-thanks">
    <div class="modal-registration-heading">
      <div class="modal-remember-title typography-menu _medium _big">
        {{ getTitle }}
      </div>
    </div>
    <div class="modal-content">
      <div class="modal-thanks__wrapper">
        <p class="modal-thanks typography-text">
          {{ getText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ModalNotification",
  computed: {
    getTitle() {
      return this.getNotificationContent.title;
    },
    getText() {
      return this.getNotificationContent.content;
    },
    ...mapGetters("modal", ["getNotificationContent"]),
  },
  mounted() {
    this.getNotificationContent.callback();
  },
  methods: {
    handleHistoryClick() {
      this.hideModal();
    },
    ...mapMutations("modal", ["hideModal"]),
  },
};
</script>
