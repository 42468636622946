<template>
  <div class="page-home page">
    <div class="container">
      <Order />
    </div>
    <Advantages />
  </div>
</template>

<script>
import Order from "@/components/order/Order";
import Advantages from "@/components/advantages/Advantages";

export default {
  name: "HomeView",
  components: {
    Order,
    Advantages,
  },
};
</script>
