import { format } from "date-fns";
import Vue from "vue";

import { DefaultAPIInstance } from "@/api";
import { AuthAPI } from "@/api/auth";
import router from "@/router";
import { UserAPI } from "@/api/user";
import { OrderAPI } from "@/api/order";

const userState = {
  userData: {
    // name: "Антон",
    // email: "example@mail.com",
    // balance: 140,
    // registration: "09.09.2022",
  },
  credentials: {
    token: localStorage.getItem("token") || null,
  },
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  refillBalance({ commit }, data) {
    OrderAPI.getPaymentLink(data)
      // eslint-disable-next-line no-shadow
      .then((response) => {
        document.location.replace(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
    refillBalanceGuest({ commit }, data) {
        OrderAPI.getPaymentLinkGuest(data)
            // eslint-disable-next-line no-shadow
            .then((response) => {
              document.location.replace(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    },
  onLogin({ commit, dispatch }, { email, password, onSuccess, onError }) {
    AuthAPI.login(email, password)
      .then((res) => {
        const data = res.data;
        commit("setToken", data.token);
        DefaultAPIInstance.defaults.headers.Authorization = `Bearer ${data.token}`;
      })
      .then(() => {
        dispatch("getUserData");
        onSuccess();
      })
      .catch((error) => {
        onError(error.response);
      });
  },
  getUserData({ commit }) {
    return UserAPI.getUserData()
      .then((res) => res.data.data)
      .then((data) => {
        const userData = {
          name: data.firstname || "",
          email: data.email,
          balance: Number(data.balance),
          registration: format(new Date(data.created_at), "dd.MM.yyyy"),
        };
        commit("setUser", userData);
        return { status: 200 };
      })
      .catch((error) => {
        localStorage.removeItem("token");
      });
  },
  logout({ commit }) {
    commit("deleteToken");
    delete DefaultAPIInstance.defaults.headers.authorization;
    router.push({ name: "home" });
  },
  changePassword(_, data) {
    return UserAPI.updateUser(data);
  },
  changePersonalInfo({ dispatch }, data) {
    return UserAPI.updateUser(data).then(() => {
      dispatch("getUserData");
      Vue.notify({
        group: "foo",
        title: "Успех!",
        type: "success",
        text: "Данные изменени успешно.",
      });
    });
  },
  signUp({ commit, dispatch }, { email, password, password_confirmation }) {
    return AuthAPI.signUp(email, password, password_confirmation)
      .then((res) => {
        const data = res.data;
        commit("setToken", data.token);
        DefaultAPIInstance.defaults.headers.Authorization = `Bearer ${data.token}`;
        return res.status;
      })
      .then((status) => {
        dispatch("getUserData");
        return status;
      });
  },
};

const mutations = {
  setUser(state, user) {
    state.userData = user;
  },
  setToken(state, token) {
    state.credentials.token = token;
    localStorage.setItem("token", token);
  },
  deleteToken(state) {
    state.credentials.token = null;
    state.userData = {};
    localStorage.removeItem("token");
  },
};

const getters = {
  getUser(state) {
    return state.userData;
  },
  isUserAuthed(state) {
    return Boolean(state.userData?.email);
  },
  getBalance(state) {
    return state.userData?.balance || 0;
  },
  getUserEmail(state) {
    return state.userData?.email || "";
  },
};

const store = {
  namespaced: true,
  state: userState,
  actions,
  mutations,
  getters,
};

export default store;
