<template>
  <div class="modal-registration modal-destination" data-modal-destination>
    <div class="modal-content">
      <h5 class="modal-destination__title typography-heading _medium">
        Куда вам удобнее ответить?<br />Там же мы Вам ответим.
      </h5>
      <div class="modal-destination__buttons">
        <button
          v-bind:class="{ 'modal-destination__button-disabled': !this.isUserAuthed, 'modal-destination__button-active': this.isUserAuthed }"
          v-bind:disabled="!this.isUserAuthed"
          class="modal-destination__button typography-menu _bold _middle"
          @click="handleDestinationEmail"
        >
          <Icon class="modal-destination__icon" name="mail" />
          На E-mail
        </button>
<!--        <button-->
<!--          class="modal-destination__button modal-destination__button-active typography-menu _bold _middle"-->
<!--          @click="handleDestinationTelegram"-->
<!--        >-->
<!--          <Icon class="modal-destination__icon" name="telega" />-->
<!--          В Телеграм-->
<!--        </button>-->

        <a
            class="modal-destination__button modal-destination__button-active typography-menu _bold _middle"
            href="https://t.me/babamabot"
            target="_blank"
        >
          <Icon class="modal-destination__icon" name="telega" />
          В Телеграм
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import Icon from "@/components/icon/Icon";
import { DESTINATIONS, MODAL_MODES } from "@/js/constants";

export default {
  name: "ModalDestination",
  components: {
    Icon,
  },
  computed: {
    ...mapGetters("user", [
        "isUserAuthed"
    ]),
  },
  methods: {
    handleDestinationTelegram() {
      this.setDestination(DESTINATIONS.telegram);
      this.setMode(MODAL_MODES.question);
    },
    handleDestinationEmail() {
      this.setDestination(DESTINATIONS.email);
      this.setMode(MODAL_MODES.question);
    },
    ...mapMutations("faq", ["setDestination"]),
    ...mapMutations("modal", ["setMode"]),
  },
};
</script>

<style lang="scss">
@import "./modal-destination.scss";
</style>
