export const MODAL_MODES = {
  reginstration: "registration",
  entry: "entry",
  thanksOrderNoReg: "thanksOrderNoReg",
  thanksAfterPay: "thanksAfterPay",
  thanksOrderHasBalance: "thanksOrderHasBalance",
  thanksOrderNoBalance: "thanksOrderNoBalance",
  thanksAfterRefillBalance: "thanksAfterRefillBalance",
  thanksQuestion: "thanksQuestion",
  remember: "remember",
  order: "order",
  promocodeInsert: "promocodeInsert",
  promocodeAccept: "promocodeAccept",
  promocodeReject: "promocodeReject",
  question: "question",
  destination: "destination",
  notification: "notification",
};

export const DESTINATIONS = {
  telegram: "telegram",
  email: "email",
};
