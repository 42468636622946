<template>
  <form class="modal-registration-reg" @submit.prevent="handleSubmit">
    <div class="modal-registration-entry__wrapper">
      <div
        class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
      >
        Ваш email
      </div>
      <div class="personal-settings-input__wrapper">
        <Input
          v-model="email"
          inputName="email"
          placeholder="example@babama.ru"
          class="personal-settings-input"
          hasValidation
          type="email"
          required
        />
      </div>
    </div>
    <div class="modal-registration-entry__wrapper">
      <div
        class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
      >
        Ваш пароль
      </div>
      <InputPassword v-model="password" inputName="password" placeholder="Password12345678" />
    </div>
    <div class="modal-registration-entry__wrapper">
      <div
        class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
      >
        Повторите ваш пароль
      </div>
      <InputPassword
        v-model="passwordRepeat"
        inputName="password-repeat"
        placeholder="Password12345678"
      />
    </div>
      <div v-if="!passwordsEquality" class="modal-registration-entry__wrapper" data-modal-registration-refresh>
        <p class="modal-remember-text-red typography-text">
            Пароли не совпадают
        </p>
      </div>
    <div class="modal-registration-entry__wrapper" data-modal-registration-refresh>
      <p class="modal-remember-text typography-menu _regular _small">
        Я принимаю условия оферты и даю СОГЛАСИЕ на обработку своих
        персональных данных на условиях, указанных в политике конфиденциальности
      </p>
    </div>
    <button class="modal-registration-reg-button" data-modal-registration-reg-button type="submit">
      <Icon class="modal-registration-reg-button__icon" name="check-square" />
      <span class="modal-registration-reg-button__text typography-menu _bold _middle">
        Зарегистрироваться
      </span>
    </button>
  </form>
</template>

<script>
import { mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import Input from "@/components/input/Input";
import InputPassword from "@/components/input-password/InputPassword";

export default {
  name: "ModalRegistrationReg",
  components: {
    Icon,
    Input,
    InputPassword,
  },
  data() {
    return {
      emailData: "",
      passwordData: "",
      passwordRepeatData: "",
    };
  },
  computed: {
    email: {
      set(value) {
        this.emailData = value;
      },
      get() {
        return this.emailData;
      },
    },
    passwordRepeat: {
      set(value) {
        this.passwordRepeatData = value;
      },
      get() {
        return this.passwordRepeatData;
      },
    },
    password: {
      set(value) {
        this.passwordData = value;
      },
      get() {
        return this.passwordData;
      },
    },
    passwordsEquality() {
        return this.passwordData === this.passwordRepeatData;
    }
  },
  methods: {
    handleSubmit() {
      const data = {
        email: this.emailData,
        password: this.passwordData,
        password_confirmation: this.passwordRepeatData,
      };
      this.$store.dispatch("user/signUp", data).then((status) => {
        if (String(status).slice(0, 1) === 2) {
          this.hideModal();
          return;
        }
        this.hideModal();
      });
    },
    ...mapMutations("modal", ["hideModal"]),
  },
};
</script>
