import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "../views/HomeView.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/faq",
    name: "faq",
    // route level code-splitting
    // this generates a separate chunk (faq.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ "../views/FaqView.vue"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import(/* webpackChunkName: "history" */ "../views/HistoryView.vue"),
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/personal-settings",
    name: "personal-settings",
    component: () => import(/* webpackChunkName: "personal" */ "../views/PersonalView.vue"),
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/personal-wallet",
    name: "personal-wallet",
    component: () => import(/* webpackChunkName: "personal" */ "../views/PersonalView.vue"),
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/fund-history",
    name: "fund-history",
    component: () => import(/* webpackChunkName: "personal" */ "../views/PersonalView.vue"),
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/payment-succeed",
    name: "payment-succeed",
    component: () => import(/* webpackChunkName: "personal" */ "../views/PaymentSucceedView.vue"),
  },
  {
    path: "/payment-failed",
    name: "payment-failed",
    component: () => import(/* webpackChunkName: "personal" */ "../views/PaymentFailedView.vue"),
  },
  {
    path: "*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "personal" */ "../views/NotFoundView.vue"),
  },
  {
    path: "/popular-questions",
    name: "popular-questions",
    component: () =>
      import(/* webpackChunkName: "popular-questions" */ "../views/PopularQuestionsView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicyView.vue"),
  },
  {
    path: "/service-rules",
    name: "service-rules",
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ "../views/ServiceRulesView.vue"),
  },
  {
    path: "/offers",
    name: "offers",
    component: () => import(/* webpackChunkName: "privacy-policy" */ "../views/OffersView.vue"),
  },
  {
    path: "/refund",
    name: "refund",
    component: () => import(/* webpackChunkName: "privacy-policy" */ "../views/RefundView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedInUserDetail = store.getters["user/isUserAuthed"];
  if (to.matched.some((el) => el.meta.authGuard) && !loggedInUserDetail) {
    if (localStorage.getItem("token")) {
      store
        .dispatch("user/getUserData")
        .then(({ status }) => {
          if (status === 200) {
            next();
          } else {
            localStorage.removeItem("token");
          }
          return null;
        })
        .catch(() => {
          localStorage.removeItem("token");
        });
    } else {
      next({ name: "home" });
    }
  }
  return next();
});

export default router;
