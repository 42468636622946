<template>
  <div class="modal-registration-heading">
    <button
      :class="['content-header-tab', 'modal-registration-tab', { _active: isEntryMode }]"
      @click="handleEntryTab"
    >
      <Icon class="content-header-tab__icon" name="login" />
      <div class="content-header-tab__title typography-menu _medium _small">Вход</div>
    </button>
    <button
      :class="['content-header-tab', 'modal-registration-tab', { _active: isRegistrationMode }]"
      @click="handleRegTab"
    >
      <Icon class="content-header-tab__icon" name="reg" />
      <div class="content-header-tab__title typography-menu _medium _small">Регистрация</div>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import { MODAL_MODES } from "@/js/constants";

export default {
  name: "ModalRegistrationHeading",
  components: {
    Icon,
  },
  computed: {
    ...mapGetters("modal", ["isRegistrationMode", "isEntryMode"]),
  },
  methods: {
    handleEntryTab() {
      if (!this.isEntryMode) {
        this.setMode(MODAL_MODES.entry);
      }
    },
    handleRegTab() {
      if (!this.isRegistrationMode) {
        this.setMode(MODAL_MODES.reginstration);
      }
    },
    ...mapMutations("modal", ["setMode"]),
  },
};
</script>
