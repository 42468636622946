<template>
  <div class="order-activity">
    <ul class="order-activity__list typography-menu _medium _small">
      <li
        v-for="activity in getActivities"
        :key="activity.id"
        :class="['order-activity__item', { _active: activity.isActive }]"
      >
        <button
          type="button"
          :class="['order-activity__button', ...getButtonClass(activity)]"
          :tabindex="activity.isDisabled && -1"
          @click="handleSelect(activity.id)"
        >
          <img :src="getIcon(activity.icon)" alt="" class="order-activity__icon" />
          {{ activity.title }}
        </button>
      </li>
    </ul>
    <div class="order-activity__select">
      <Select
        :items="getActivities"
        :defaultValue="getDefaultActivities"
        selectName="order-activity"
        class="order-activity__select"
        @handleSelect="handleSelect"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Select from "@/components/select/Select";

export default {
  name: "OrderActivity",
  components: {
    Select,
  },
  computed: {
    ...mapGetters("order", [
      "getActivities",
      "getDefaultActivities",
      "getCountries",
      "getSelectedQualityActivity",
    ]),
  },
  data() {
    return {
      defaultValueCountries: {
        id: 0,
        title: "Любая",
        value: null,
        isActive: true,
        isDisabled: false,
      },
    };
  },
  methods: {
    getIcon(icon) {
      return icon ? `${process.env.VUE_APP_BASE_URL}/${icon}` : "./images/default.png";
    },
    getButtonClass(activity) {
      return [
        { _active: activity.isActive },
        { _disabled: activity.isDisabled },
      ];
    },
    handleSelect(id) {
      if (this.getActivities.find((item) => item.id === id).isActive) {
        return;
      }
      if (this.getDefaultActivities.isActive) {
        this.setDefaultActivities({
          ...this.getDefaultActivities,
          isActive: false,
          hasComment: false,
        });
      }
      this.getActivities.forEach((item) => {
        item.isActive = false;
      });
      const activeActivity = this.getActivities.find((item) => item.id === id);
      this.$store.dispatch("order/setActivity", activeActivity.id);
      activeActivity.isActive = true;
      this.resetParameters();
    },
    resetParameters() {
      this.setCountry(null);
      // this.setGender(null);
      this.setCountries([]);
      this.setGenders([]);
      this.setParameterPriceDiff(0);
    },
    ...mapMutations("order", [
      "setDefaultActivities",
      "setCountry",
      "setCount",
      "setGender",
      "setGenders",
      "setCountries",
      "setParameterPriceDiff",
    ]),
  },
};
</script>

<style lang="scss">
@import "./order-activity.scss";
</style>
