<template>
  <header class="header">
    <div class="container header__container">
      <div class="header__left">
        <router-link to="/" :class="['header__logo', { _disabled: $route.name === 'home' }]">
          <Icon name="logo" class="header__logo-icon" />
        </router-link>
        <p class="header__text typography-menu _regular _middle">
          Живые лайки, подписчики, комментарии и другие активности для 10 социальных сетей.
        </p>
      </div>
      <Profile v-if="isUserAuthed" />
      <ProfileNoUser v-if="!isUserAuthed" />
    </div>
    <p class="header__text header__text_mobile typography-menu _regular _middle">
      Живые лайки, подписчики, комментарии и другие активности для 10 социальных сетей.
    </p>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

import Icon from "@/components/icon/Icon";
import Profile from "@/components/profile/Profile";
import ProfileNoUser from "@/components/profile-no-user/ProfileNoUser";

export default {
  name: "Header",
  components: {
    Icon,
    Profile,
    ProfileNoUser,
  },
  computed: {
    ...mapGetters("user", ["isUserAuthed"]),
  },
  mounted() {
    this.user = null;
  },
};
</script>

<style lang="scss" scoped>
@import "./header.scss";
</style>
