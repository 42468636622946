const parameters = {
  default_quality_activities: {
    id: 0,
    title: "Выберите из списка",
    description: "",
    value: "none",
  },
  quality_activities: [
    {
      id: 1,
      title: "Боты",
      value: "bots",
    },
    {
      id: 2,
      title: "Смешанное",
      value: "mix",
    },
  ],
  genders: [
    {
      id: 1,
      title: "Мужской",
      value: "man",
    },
    {
      id: 2,
      title: "Женский",
      value: "woman",
    },
  ],
  countries: [
    {
      id: 1,
      title: "Россия",
      value: "ru",
    },
    {
      id: 2,
      title: "СНГ",
      value: "sng",
    },
    {
      id: 3,
      title: "США",
      value: "usa",
    },
    {
      id: 4,
      title: "Белоруссия",
      value: "br",
    },
    {
      id: 5,
      title: "Украина",
      value: "ua",
    },
    {
      id: 6,
      title: "Казахстан",
      value: "kz",
    },
    {
      id: 7,
      title: "Узбекистан",
      value: "uz",
    },
    {
      id: 8,
      title: "Таджикистан",
      value: "tg",
    },
    {
      id: 9,
      title: "Китай",
      value: "ch",
    },
    {
      id: 10,
      title: "Турция",
      value: "tur",
    },
  ],
  costPerThousand: 0,
};

export default parameters;
