<template>
  <div class="input-number">
    <button
      type="button"
      class="input-number__button input-number__button_minus"
      @click="handleMinus"
    >
      <Icon class="input-number__icon" name="minus-square" />
    </button>
    <input
      :name="inputName"
      class="input input-number__input typography-menu _medium _big"
      :type="type"
      :placeholder="placeholder"
      min="0"
      :value="value"
      @input="handleInput"
    />
    <button
      type="button"
      class="input-number__button input-number__button_plus"
      @click="handlePlus"
    >
      <Icon class="input-number__icon" name="plus-square" />
    </button>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon";
export default {
  name: "InputNumber",
  components: { Icon },
  props: {
    inputName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
      required: false,
    },
  },
  methods: {
    handlePlus() {
      this.$emit("input", Number(this.value) + 1);
    },
    handleMinus() {
      this.$emit("input", Number(this.value) > 1 ? Number(this.value) - 1 : 0);
    },
    handleInput(event) {
      this.$emit("input", Number(event.target.value));
    },
  },
};
</script>

<style lang="scss">
@import "./input-number.scss";
</style>
