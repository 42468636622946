<template>
  <div id="app">
    <notifications group="foo" />
    <Header />
    <div class="container">
      <ContentHeader />
    </div>
    <router-view />
    <Footer />
    <Modal />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import "./reset.css";

import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import ContentHeader from "@/components/content-header/ContentHeader";
import Modal from "@/components/modal/Modal";

export default {
  name: "App",
  components: {
    ContentHeader,
    Header,
    Footer,
    Modal,
  },
  computed: {
    ...mapState("user", ["credentials"]),
  },
  mounted() {
    if (this.credentials?.token) {
      this.$store.dispatch("user/getUserData");
    }
  },

  methods: {
    ...mapMutations("modal", ["showModal", "setMode", "hideModal"]),
  },
};
</script>

<style lang="scss">
@import "@/sass/styles.scss";
</style>
