<template>
    <div class="modal-registration modal-order">
        <div class="modal-registration-heading">
            <div class="modal-remember-title modal-order-title typography-heading _bold">
                Подтверждение заказа
            </div>
        </div>
        <div class="modal-content">
            <form class="modal-order-form" @submit.prevent="handleSubmit">
              <div class="modal-order-form__group">
                <span class="modal-order-form__name typography-menu _regular _middle">Соц. сеть:</span>
                <input
                    type="text"
                    class="modal-order-form__input _disabled"
                    data-modal-order-service
                    :value="getPlatforms.find((item) => item.id === getPlatform).title"
                />
              </div>
                <div class="modal-order-form__group">
                    <span class="modal-order-form__name typography-menu _regular _middle">Услуга:</span>
                    <input
                        type="text"
                        class="modal-order-form__input _disabled"
                        data-modal-order-service
                        :value="selectedActivityName"
                    />
                </div>
                <div class="modal-order-form__group">
                    <span class="modal-order-form__name typography-menu _regular _middle">Количество:</span>
                    <input
                        type="text"
                        class="modal-order-form__input _disabled"
                        data-modal-order-count
                        :value="getCount + ' шт.'"
                    />
                </div>
                <div class="modal-order-form__group">
                    <span class="modal-order-form__name typography-menu _regular _middle">Ссылка:</span>
                    <input
                        ref="link"
                        v-model="link"
                        type="text"
                        class="modal-order-form__input"
                        data-modal-order-link
                    />
                    <a
                        class="modal-order-form__link typography-menu _regular"
                        data-modal-order-link-change
                        @click="$refs.link.focus()"
                    >Изменить</a
                    >
                </div>
                <div v-show="showComment" class="modal-order-form__group _no-flex">
                    <span class="modal-order-form__name typography-menu _regular _middle">Комментарий:</span>
                    <input v-model="comment" class="modal-order-form__text" />
                </div>
                <div class="modal-order-form-summary">
                    <h6 class="modal-order-form-summary__title typography-heading _regular">
                        Итоговая сумма с учетом комиссии:
                    </h6>
                    <div class="flex items-baseline typography _bold">
                        <input
                            name="order-summary"
                            type="text"
                            class="modal-order-form-summary__input"
                            :value="formattedSummary"
                        />
                        <span class="modal-order-form-summary__number">{{ formattedSummary }}</span>
                        <span class="modal-order-form-summary__currency">₽</span>
                    </div>
                    <div class="personal-wallet-group__wrapper" v-show="!this.isUserAuthed">
                        <span class="modal-order-form__name typography-menu _regular _middle">
                            Платежная система
                        </span>
                        <SelectService />
                    </div>
                    <button type="submit" class="modal-order-form-summary__submit">
                        <Icon class="modal-order-form-summary__submit-icon" name="credit-card" />
                        <span class="modal-order-form-summary__submit-text typography-menu _bold _middle"
                        >Оплатить</span
                        >
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import { MODAL_MODES } from "@/js/constants";
import { OrderAPI } from "@/api/order";
import SelectService from "@/components/select-service/SelectService.vue";

export default {
    name: "ModalOrder",
    components: {
        SelectService,
        Icon,
    },
    computed: {
        formattedSummary() {
            return this.getTotalPrice.toFixed(2);
        },
        link: {
            set(value) {
                this.setLink(value);
            },
            get() {
                return this.getLink;
            },
        },
        email: {
            set(value) {
              this.setEmail(value);
            },
            get() {
                return this.getEmail;
            },
        },
        platform: {
          set(value) {
            this.setPlatform(value);
          },
          get() {
            return this.getPlatform;
          },
        },
        comment: {
            set(value) {
                this.setMessage(value);
            },
            get() {
                return this.getMessage;
            },
        },
        selectedActivityName() {
            return this.getSelectedQualityActivity?.title;
        },
        showComment() {
            return this.getSelectedActivity?.has_comment;
        },
        ...mapGetters("user", ["getBalance", "isUserAuthed"]),
        ...mapGetters("order", [
            "getTotalPrice",
            "isPromoActivated",
            "getCount",
            "getLink",
            "getSelectedQualityActivity",
            "getSelectedActivity",
            "getMessage",
            "getEmail",
            "getTotalPrice",
            "getLastUnpaidOrder",
            "getPlatform",
            "getPlatforms",
            "getOrderId",
            "getPromocodeCode"
        ]),
    },
    methods: {
        processSuccessStateOrder() {
            this.$store.dispatch("user/getUserData");
            this.setMode(MODAL_MODES.thanksOrderHasBalance);
            this.showModal();
            this.setLastUnpaidOrder(null);
        },
        handleSubmit() {
            const formData = new FormData(event.target);
            if (!this.isUserAuthed) {
                const data = {
                    amount: this.getTotalPrice,
                    platform: formData.get("pay-service"),
                    email: this.getEmail,
                    order_id: this.getOrderId,
                    promocode_code: this.getPromocodeCode,
                };
                this.$store.dispatch("user/refillBalanceGuest", data);
                this.setMode(MODAL_MODES.thanksOrderNoReg);
                this.showModal();
                return;
            }

            if (this.getBalance < this.getTotalPrice) {
                this.setMode(MODAL_MODES.thanksOrderNoBalance);
                this.showModal();
            }

            const unpaidOrder = this.getLastUnpaidOrder;

            if (unpaidOrder) {
                OrderAPI.repayOrder(unpaidOrder.id)
                    .then(() => this.processSuccessStateOrder)
                    .catch((error) => {
                        console.error(error);
                    });

                return;
            }
            this.$store
                .dispatch("order/sendOrder")
                .then(res => res.json)
                .then(res => {
                  this.setOrderId(res.order_id)
                  this.processSuccessStateOrder
                })

                .catch((error) => {
                    console.error(error);
                });
        },
        ...mapMutations("modal", ["showModal", "setMode"]),
        ...mapMutations("order", ["setLink", "setMessage", "setLastUnpaidOrder", "setEmail", "setPlatform", "setOrderId"]),
    },
};
</script>

<style lang="scss">
@import "./modal-order.scss";
</style>
