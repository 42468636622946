import { DefaultAPIInstance } from "@/api";

export const UserAPI = {
  getUserData() {
    const url = "/user";
    return DefaultAPIInstance.get(url);
  },

  updateUser(data) {
    const url = `/user`;
    return DefaultAPIInstance.put(url, data);
  },
};
