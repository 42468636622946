import { DefaultAPIInstance, LoginAPIInstance } from "@/api";

export const AuthAPI = {
  login(email, password) {
    const url = "/login";
    const data = { email, password };
    return LoginAPIInstance.post(url, data);
  },

  forgotPassword(email) {
    const url = "/forgot-password";
    const data = { email };
    return DefaultAPIInstance.post(url, data);
  },

  logout() {
    const url = "/logout";
    return DefaultAPIInstance.post(url);
  },

  signUp(email, password, password_confirmation) {
    const url = "/signup";
    const data = { email, password, password_confirmation };
    return LoginAPIInstance.post(url, data);
  },
};
