<template>
  <component :is="getComponent" :class="getIconClass" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    fillCurent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getIconClass() {
      return [
        "icon",
        `icon-${this.name}`,
        { "icon-fill-current": this.fillCurent },
        { "icon-inline": this.inline },
      ];
    },
    getComponent() {
      return () =>
        import(
          /* webpackChunkName: "icons" */
          /* webpackMode: "lazy-once" */
          `./icons/icon-${this.name}.vue`
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  &-fill-current {
    fill: currentColor;
  }

  &-inline {
    height: 1em;
    width: 1em;
    line-height: 1;
  }
}
</style>
