<template>
  <div class="profile-no-user" data-profile-no-user>
    <button class="profile-no-user__button" data-profile-enter @click="handleShowEntry">
      <Icon name="login-box" class="profile-no-user__icon" />
      <div class="profile-no-user__text typography-menu _bold _small">Войти</div>
    </button>
    <button
      class="profile-no-user__button"
      data-profile-registration
      @click="handleShowRegistration"
    >
      <Icon name="user-follow" class="profile-no-user__icon" />
      <div class="profile-no-user__text typography-menu _bold _small">Зарегистрироваться</div>
    </button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import { MODAL_MODES } from "@/js/constants";

export default {
  name: "ProfileNoUser",
  components: {
    Icon,
  },
  methods: {
    handleShowEntry() {
      this.setMode(MODAL_MODES.entry);
      this.showModal();
    },
    handleShowRegistration() {
      this.setMode(MODAL_MODES.reginstration);
      this.showModal();
    },
    ...mapMutations("modal", ["showModal", "setMode"]),
  },
};
</script>

<style lang="scss">
@import "./profile.scss";
</style>
