<template>
  <div :class="getClass" @click="$emit('handleSelect')">
    <img v-if="icon" :src="icon" alt="" class="select-icon" />
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "SelectOption",
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getClass() {
      return ["select-custom-option", { _disabled: this.isDisabled }, { isActive: this.isActive }];
    },
  },
  methods: {
    getIcon(icon) {
      return icon ? `${process.env.VUE_APP_BASE_URL}/${icon}` : "./images/default.png";
    },
  },
};
</script>
