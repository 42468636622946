<template>
  <div class="order-platform">
    <ul class="order-platform__list typography-menu _medium _small" data-order-platform-list>
      <li
        v-for="platform in getPlatforms"
        :key="platform.id"
        :class="['order-platform__item', ...getExtraClass(platform)]"
      >
        <button
          type="button"
          :class="['order-platform__button', ...getExtraClass(platform)]"
          :tabindex="platform.isDisabled && -1"
          @click="handleSelect(platform.id)"
        >
          <img :src="getIcon(platform.icon)" alt="" class="order-platform__icon" />
          {{ platform.title }}
        </button>
      </li>
    </ul>
    <div class="order-platform__select">
      <Select
        :items="getPlatforms"
        :defaultValue="defaultValue"
        selectName="order-platform"
        class="order-platform__select"
        @handleSelect="handleSelect"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Select from "@/components/select/Select";

export default {
  name: "OrderPlatform",
  components: {
    Select,
  },
  data() {
    return {
      defaultValue: {
        id: 0,
        title: "Выберите площадку",
        value: "",
        isActive: true,
        isDisabled: true,
      },
    };
  },
  computed: {
    ...mapGetters("order", ["getPlatforms"]),
  },
  methods: {
    getIcon(icon) {
      return icon ? `${process.env.VUE_APP_BASE_URL}/${icon}` : "./images/default.png";
    },
    getExtraClass(platform) {
      return [{ _active: platform.isActive }, { _disabled: platform.isDisabled }];
    },
    handleSelect(id) {
      if (this.getPlatforms.find((item) => item.id === id).isActive) {
        return;
      }
      if (this.defaultValue.isActive) {
        this.defaultValue.isActive = false;
      }
      this.getPlatforms.forEach((item) => {
        item.isActive = false;
      });
      const activePlatform = this.getPlatforms.find((item) => item.id === id);
      activePlatform.isActive = true;
      this.$store.dispatch("order/setPlatform", activePlatform.id);
    },
  },
};
</script>

<style lang="scss">
@import "./order-platform";
</style>
