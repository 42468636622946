import Vue from "vue";
import VueYandexMetrika from 'vue-yandex-metrika'
import Notifications from "vue-notification";

import App from "./App.vue";
import router from "./router";
import store from "./store";
/* eslint-disable */

import moment from 'moment-timezone';




Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VueYandexMetrika, {
  id: 93358698,
  router: router,
  env: process.env.NODE_ENV
  // other options
})
// eslint-disable-next-line
Vue.prototype.moment = moment
new Vue({
  router,
  store,
  // eslint-disable-next-line id-length
  render: (h) => h(App),
}).$mount("#app");
