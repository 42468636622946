<template>
  <div class="modal-registration modal-promocode">
    <div class="modal-registration-heading">
      <div class="modal-remember-title typography-menu _medium _big">Напишите Ваш вопрос:</div>
    </div>
    <div class="modal-content">
      <form class="modal-registration-entry" @submit.prevent="handleSubmit">
        <div class="modal-registration-entry__wrapper">
          <div
            class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
          >
            Ваше имя
          </div>
          <div class="personal-settings-input__wrapper">
            <Input
              v-model="name"
              inputName="name"
              placeholder="Как заказать раскрутку?"
              class="personal-settings-input"
            />
          </div>
        </div>
        <div class="modal-registration-entry__wrapper">
          <div
            class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
          >
            Ваш {{ isDestinationEmail ? "email" : "telegram" }} (куда отправить)
          </div>
          <div v-if="isDestinationEmail" class="personal-settings-input__wrapper">
            <Input
              v-model="email"
              inputName="email"
              placeholder="example@mail.ru"
              class="personal-settings-input"
              type="email"
              required
            />
          </div>
          <div v-if="isDestinationTelegram" class="personal-settings-input__wrapper">
            <Input
              v-model="email"
              inputName="telegram"
              placeholder="@username"
              class="personal-settings-input"
              required
            />
          </div>
        </div>
        <div class="modal-registration-entry__wrapper">
          <div
            class="order-parameters__item-heading personal-settings-group__heading typography-menu _regular"
          >
            Ваш вопрос
          </div>
          <div class="personal-settings-input__wrapper">
            <Textarea
              v-model="message"
              textareaName="question"
              className="typography-menu _regular _small"
              placeholder="Пример вопроса"
              :maxlength="1000"
              required
            />
          </div>
        </div>
        <div class="modal-registration-entry__buttons modal-remember__buttons">
          <button
            class="modal-registration-entry-button modal-promocode__button modal-remember__button typography-menu _bold _middle"
            type="submit"
          >
            Отправить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Input from "@/components/input/Input";
import Textarea from "@/components/textarea/Textarea";
import { DESTINATIONS, MODAL_MODES } from "@/js/constants";

export default {
  name: "ModalQuestion",
  components: {
    Input,
    Textarea,
  },
  computed: {
    isDestinationEmail() {
      return this.getDestination === DESTINATIONS.email;
    },
    isDestinationTelegram() {
      return this.getDestination === DESTINATIONS.telegram;
    },
    name: {
      set(value) {
        this.setFormData({ name: value });
      },
      get() {
        return this.getFormData.name;
      },
    },
    message: {
      set(value) {
        this.setFormData({ message: value });
      },
      get() {
        return this.getFormData.message;
      },
    },
    email: {
      set(value) {
        this.setFormData({ email: value });
      },
      get() {
        return this.getFormData.email;
      },
    },
    ...mapGetters("faq", ["getDestination", "getFormData"]),
    ...mapGetters("user", ["getUser"]),
  },
  mounted() {
    if (this.getUser) {
      this.setFormData({ name: this.getUser.name });
    }
  },
  methods: {
    handleSubmit() {
      this.setFormData({
        subject: this.isDestinationEmail ? "Собщения на email" : "Собщения в telegram",
      });
      this.$store.dispatch("faq/sendQuestion");
      this.setMode(MODAL_MODES.thanksQuestion);
    },
    ...mapMutations("faq", ["setFormData"]),
    ...mapMutations("modal", ["setMode"]),
  },
};
</script>

<style></style>
