<template>
  <button :class="['button', typeButton && `button-${typeButton}`]">
    <div v-if="typeButton === 'details'" class="button-details__dots" />
    <Icon v-if="typeButton === 'repeat'" class="button-repeat-icon" name="refresh" />
    <Icon v-if="typeButton === 'question'" class="button-question-icon" name="question" />
    <Icon v-if="typeButton === 'personal-wallet'" class="button-personal-wallet-icon" name="bank" />
    <span v-if="$slots.default" :class="['button-text', typeButton && `button-${typeButton}-text`]">
      <slot />
    </span>
  </button>
</template>

<script>
import Icon from "@/components/icon/Icon";

export default {
  name: "Button",
  components: {
    Icon,
  },
  props: {
    typeButton: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@import "./button.scss";
</style>
