import Vue from "vue";
import Vuex from "vuex";

import user from "./user";
import modal from "./modal";
import order from "./order";
import faq from "./faq";
import history from "./history";

import staticPages from "@/store/static-pages";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    modal,
    order,
    faq,
    history,
    staticPages,
  },
});
