<template>
  <div class="modal-registration modal-thanks">
    <div class="modal-registration-heading">
      <div
        v-if="!isModalThanksQuestion
        && !isModalThanksOrderNoBalance
        && !isModalThanksAfterRefillBalance
        && !isModalThanksOrderNoReg
        && !isModalThanksAfterPay"
        class="modal-remember-title typography-menu _medium _big"
      >
        Спасибо за заказ!
      </div>
      <div
        v-if="isModalThanksOrderNoReg"
        class="modal-remember-title typography-menu _medium _big"
      >
        Ваш заказ отправлен в работу.
      </div>
      <div
        v-if="isModalThanksOrderNoBalance"
        class="modal-remember-title typography-menu _medium _big"
      >
        Оформление заказа
      </div>
      <div v-if="isModalThanksAfterPay" class="modal-remember-title typography-menu _medium _big">
        Заказ отправлен в работу
      </div>
      <div v-if="isModalThanksQuestion" class="modal-remember-title typography-menu _medium _big">
        Спасибо за Ваш вопрос
      </div>
      <div v-if="isModalThanksAfterRefillBalance" class="modal-remember-title typography-menu _medium _big">
        Баланс пополнен успешно
      </div>
    </div>
    <div class="modal-content">
        <div v-if="isModalThanksOrderNoReg" class="modal-thanks__wrapper">
          <p class="modal-thanks typography-text">
              Мы сохранили Ваш заказ в истории и сейчас переведем Вас на страницу оплаты
          </p>
        </div>
      <!-- После оплаты -->
      <div v-if="isModalThanksAfterPay">
          <p v-if="isNewUser" class="modal-thanks modal-thanks__text typography-text">
              Вы можете отслеживать процесс выполнения в "Истории Заказа".
              А так же, на Вашу почту мы только что отправили пароль по которому Вы можете заходить в свой личный кабинет на нашем сервисе.
              <br/><br/>
              А если что-то пошло не так или есть вопросы, то пишите в
              <span class="typography-menu _link"
                    @click="handleQuestionClick">
                  тех поддержку
              </span>
          </p>
          <p v-if="!isUserAuthed && !isModalThanksOrderNoReg" class="modal-thanks modal-thanks__text typography-text">
              Вы можете отслеживать процесс выполнения в "Истории Заказа".
              Но перед этим Вам нужно авторизироваться в личном кабинете.
              <br/><br/>
              Если не помните доступ, воспользуйтесь восстановления пароля или проверьте Вашу почту, он был отправлен туда при первом заказе.
              А если что-то пошло не так или есть вопросы, то пишите в
              <span class="typography-menu _link"
                    @click="handleQuestionClick">
                  тех поддержку
              </span>
          </p>
          <p v-if="isUserAuthed && !isNewUser" class="modal-thanks modal-thanks__text typography-text">
              Вы можете отслеживать процесс выполнения в "Истории Заказа".
              <br/><br/>
              А если что-то пошло не так или есть вопросы, то пишите в
              <span class="typography-menu _link"
                    @click="handleQuestionClick">
                  тех поддержку
              </span>
          </p>
          <router-link
            v-if="isUserAuthed || isModalThanksOrderNoReg"
            to="/history"
            class="modal-thanks-button modal-thanks-button_history"
            @click.native="handleHistoryClick"
          >
              <Icon class="modal-thanks-button__icon modal-thanks-button__icon_ico" name="paper" />
              <span class="modal-thanks-button__text typography-menu _bold _middle">История заказов</span>
          </router-link>
<!--          <router-link-->
<!--            v-if="isUserAuthed || isModalThanksOrderNoReg"-->
<!--            to="/history"-->
<!--            class="modal-thanks-button __question modal-thanks-button_history"-->
<!--            @click.native="handleQuestionClick"-->
<!--          >-->
<!--              <Icon class="modal-thanks-button__icon modal-thanks-button__icon_ico" name="question" />-->
<!--              <span class="modal-thanks-button__text __question typography-menu _bold _middle">-->
<!--                Задать вопрос-->
<!--            </span>-->
<!--          </router-link>-->
      </div>
      <!-- Вопрос -->
      <div v-if="isModalThanksQuestion" class="modal-thanks__wrapper">
        <p class="modal-thanks typography-text">
          Обычно мы отвечаем в течении 24 часов. Бывает быстрее. Но если сейчас выходные или
          праздники, то этот срок может увеличиться до первого рабочего дня.
        </p>
      </div>
      <!-- Зареган но не хватает средств -->
      <div v-if="isModalThanksOrderNoBalance" class="modal-thanks__wrapper">
        <p class="modal-thanks__text typography-text">
          Заказ создан, но пока еще не оплачен. Пополните пожалуйста баланс. Можно сразу с запасом,
          чтобы осталось на будущие заказы.
        </p>
        <div class="flex items-center">
          <span
            class="modal-order-form__name typography-menu _regular _middle modal-thanks__input-text"
            >Ваш баланс:</span
          >
          <input
            type="text"
            class="modal-order-form__input modal-thanks__input _disabled"
            :value="getCurrentBalance"
          />
        </div>
        <div class="modal-order-form__group flex items-center mt-3">
          <span
            class="modal-order-form__name typography-menu _regular _middle modal-thanks__input-text"
            >Нужно еще для заказа:</span
          >
          <input
            type="text"
            class="modal-order-form__input modal-thanks__input _disabled"
            :value="getDeltaPrice < 0 ? 0 : getDeltaPrice"
          />
        </div>
        <form class="modal-thanks__form" @submit.prevent="handleSubmit">
          <RefilWallet class="modal-thanks__group" :defaultValue="getDeltaPriceAsFloat" />
          <div
            class="personal-wallet-group personal-wallet-group_submit modal-thanks__group modal-thanks__group_submit"
          >
            <div class="order-parameters__item-heading typography-menu _regular _middle">
              <span class="order-parameters__item-title"> Платежная система </span>
            </div>
            <div class="personal-wallet-group__wrapper modal-thanks__group-wrapper">
              <SelectService class="modal-thanks__group-item" />
              <Button
                typeButton="personal-wallet"
                type="submit"
                class="personal-wallet-submit typography-menu _bold modal-thanks__group-item"
                >
                  Перейти к оплате
              </Button>
            </div>
          </div>
        </form>
      </div>
      <!-- Зареган и баланса достаточно -->
      <div v-if="isModalThanksOrderHasBalance" class="modal-thanks__wrapper">
        <p class="modal-thanks typography-text">
          Заказ создан и отправлен в работу. Результаты Вы можете увидеть в "Истории заказов"
        </p>
        <router-link
          v-if="isUserAuthed"
          to="/history"
          class="modal-thanks-button modal-thanks-button_history"
          @click.native="handleHistoryClick"
        >
          <Icon class="modal-thanks-button__icon modal-thanks-button__icon_ico" name="paper" />
          <span class="modal-thanks-button__text typography-menu _bold _middle"
            >Перейти в историю заказов</span
          >
        </router-link>
      </div>
      <!-- После пополения баланса в лк -->
      <div v-if="isModalThanksAfterRefillBalance" class="modal-thanks__wrapper">
        <p class="modal-thanks typography-text">
            Теперь Вы можете в любой момент воспользоваться любыми активностями оплачивая деньгами с баланса.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Icon from "@/components/icon/Icon";
import Button from "@/components/button/Button";
import SelectService from "@/components/select-service/SelectService";
import RefilWallet from "@/components/refil-wallet/RefilWallet";
import {MODAL_MODES} from "@/js/constants";

export default {
  name: "ModalThanks",
  components: {
    Icon,
    Button,
    SelectService,
    RefilWallet,
  },
  computed: {
    getCurrentBalance() {
      return `${this.getBalance.toFixed(2)} ₽`;
    },
    getDeltaPrice() {
      return `${this.getDeltaPriceAsFloat.toFixed(2)}  ₽`;
    },
    getDeltaPriceAsFloat() {
      if (!this.getBalance) {
        return this.getTotalPrice;
      }
      return this.getTotalPrice - this.getBalance;
    },
    ...mapGetters("modal", [
      "isModalThanksOrderNoReg",
      "isModalThanksOrderHasBalance",
      "isModalThanksOrderNoBalance",
      "isModalThanksQuestion",
      "isModalThanksAfterRefillBalance",
      "isModalThanksAfterPay",
    ]),
    ...mapGetters("user", ["getBalance", "isUserAuthed"]),
    ...mapGetters("order", ["getTotalPrice", "getOrderId", "isNewUser"]),
  },
  methods: {
    handleHistoryClick() {
      this.hideModal();
    },
    handleQuestionClick() {
      this.hideModal();
      this.setMode(MODAL_MODES.destination);
      this.showModal();
    },
    handleSubmit() {
      const formData = new FormData(event.target);
      const data = {
        amount: formData.get("amount"),
        platform: formData.get("pay-service"),
        order_id: this.getOrderId
      };
      this.$store.dispatch("user/refillBalance", data);

      this.setMode(MODAL_MODES.thanksOrderNoReg);
      this.showModal();
    },
    ...mapMutations("modal", ["hideModal", "showModal", "setMode"]),
  },
};
</script>

<style lang="scss">
@import "./modal-thanks.scss";
</style>
