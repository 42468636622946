<template>
  <Select
    selectName="pay-service"
    :items="getPaymentSystems"
    :defaultValue="defaultValue"
    class="personal-wallet-select select_small _small"
    @handleSelect="handleSelect"
  />
</template>

<script>
import { mapGetters } from "vuex";

import Select from "@/components/select/Select";

export default {
  name: "SelectService",
  components: {
    Select,
  },
  data() {
    return {
      defaultValue: {
        title: "Выберите из списка",
        icon: "",
        value: "-",
        isActive: true,
        isDisabled: true,
      },
    };
  },
  computed: {
    ...mapGetters("order", ["getPaymentSystems"]),
  },
  mounted() {
    this.$store.dispatch("order/getPaymentSystems");
    this.defaultValue.isActive = false;
  },
  methods: {
    handleSelect(id) {
      if (this.defaultValue.isActive) {
        this.defaultValue.isActive = false;
      }

      this.getPaymentSystems.forEach((item) => {
        if (item) item.isActive = false;
      });

      const service = this.getPaymentSystems.find((item) => item.id === id);

      if (service) service.isActive = true;
    },
  },
};
</script>
