<template>
  <div class="modal-registration">
    <slot name="heading" />
    <div class="modal-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalRegistration",
};
</script>

<style lang="scss">
@import "./modal-registration.scss";
</style>
