import { FaqAPI } from "@/api/faq";
import mockedFaq from "@/data/mocked/faq";

const faqState = {
  destination: null,
  formData: {
    name: null,
    email: "",
    message: "",
  },
  faqData: null,
};

const actions = {
  sendQuestion({ getters }) {
    console.info(getters.getFormData);
    FaqAPI.sendTicket(getters.getFormData).then((res) => {
      console.info(res);
    });
  },
  async initFaqData({ commit }) {
    const requestFaq = (await FaqAPI.getTickets()).data;
    commit("setFaqData", requestFaq);
  },
};

const mutations = {
  setDestination(state, target) {
    state.destination = target;
  },
  setFormData(state, data) {
    state.formData = {
      ...state.formData,
      ...data,
    };
  },
  setFaqData(state, data) {
    state.faqData = data;
  },
};

const getters = {
  getDestination(state) {
    return state.destination;
  },
  getFormData(state) {
    return state.formData;
  },
  getFaqData(state) {
    return state.faqData;
  },
};

const store = {
  namespaced: true,
  state: faqState,
  actions,
  mutations,
  getters,
};

export default store;
